import React from 'react';
import { MDBRow, MDBCol, MDBIcon } from 'mdbreact';
import { MDBTooltip } from 'mdb-react-ui-kit';
import { withUserContext } from '../../../contexts/UserContext';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Popover from '@material-ui/core/Popover';
import BasicButton from '../../SharedComponents/BasicButton/BasicButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import TablePagination from '@material-ui/core/TablePagination';
import { cueSheetConfig, initialCueSheetConfig, initialCueSheetHeaderConfig, initialStatusConfig } from './Config';
import { handlePaginationList } from '../../../Common/TableHelper';
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";
import NotificationComponent from '../../../Common/NotificationComponent/NotificationComponent';
import BasicLabel from '../../SharedComponents/BasicLabel/BasicLabel';
import SelectField from '../../SharedComponents/SelectField/SelectField';
import CueSheetEditDetails from './CueSheetEditDetails';
import CueSheetCopyVersion from './CueSheetCopyVersion';
import * as Constants from '../../../constants/constants';
import ClearTrackService from '../../../services/service';
import CuesheetTableComponet from '../EpisodeAndClearenceDetails/CuesheetTableComponent';

export default withUserContext(class CueSheet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showSeasonId: props?.showSeasonId,
            showSeasonEpisodeId: props?.showSeasonEpisodeId,
            cueSheetDetails: JSON.parse(JSON.stringify({ ...initialCueSheetConfig })),
            cueSheetHeaderDetails: JSON.parse(JSON.stringify({ ...initialCueSheetHeaderConfig })),
            showSeasonEpisodeCueSheetRecords: {},
            isFetchingCueSheetDetails: false,
            expanded: false,
            setExpanded: false,
            renderList: [],
            config: JSON.parse(JSON.stringify({ ...cueSheetConfig })),
            showRecurringModal: false,
            compositions: [],
            showEditDetailsPopup: false,
            page: 0,
            rowsPerPage: 250,
            selectedAiring: null,
            selectedRecurringSong: null,
            cueSheetRecordData: null,
            airingList: [],
            headingsData: null,
            isSubmit: false,
            showSeasonComposerModal: false,
            composerOptions:[],
            selectedComposer: null,
            composerDetalis: [],
            approveStatus : false,
            rejectedStatus: false,
            compositionOption:[],
            recurringList:[],
            isPreviewLoader: false,
            showImportCueSheet:false,
            divisionData: null,
            currentUser: null
        }
    };

    handleChange = (panel) => (event, isExpanded) => {
        this.setState({ expanded: isExpanded,isFetchingCueSheetDetails: true , divisionData : this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id === this.props?.division_id) }, () => {
            if (this.state.expanded) {
                this.getEntity('musicUses', 'MUSIC_USES', '');
                this.getCueSheetHeader();
                this.getRecurringCueDetails();
                this.getComposerDetails()
            }
        });
    };



    getCueSheetHeader = (headerId = null) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonEpisodeCueSheetHeaders?show_season_id=${this.state.showSeasonId}&show_season_episode_id=${this.state.showSeasonEpisodeId}`,
            this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(response => {
                this.setState({ cueHeaderLength: response.data.length }, () => {
                    if (this.state.cueHeaderLength > 0) {
                        let airingOptions = response.data.map((item) => ({ label: item.airing, value: item.cue_sheet_header_id }))
                        this.setState({
                            headingsData: response.data,
                            airingList: airingOptions,
                            cueSheetHeaderDetails: headerId ? response.data?.find(item => item?.cue_sheet_header_id === headerId) : response.data[0],
                            selectedAiring: headerId ? headerId : response.data[0]?.cue_sheet_header_id
                        })
                        this.getCueSheetDetails(headerId ? headerId : response.data[0]?.cue_sheet_header_id)
                    }
                    else {
                        this.setState({
                            airingList: [], headingsData: null, cueSheetHeaderDetails: null, selectedAiring: null, cueSheetRecordData: null,
                            page: 0,
                            renderList: [],
                            isFetchingCueSheetDetails: false, isSubmit: false
                        }, this.getCompositionList)
                    }
                })
            })
    }

    getCueSheetHeaderId = (id = null) => {
        this.setState({ selectedAiring: id })
        this.getCueSheetHeader(id)
    }

    getCueSheetDetails = (cueSheetHeaderId, loader=true) => {
        this.setState({ isFetchingCueSheetDetails: loader , selectedAiring:cueSheetHeaderId})
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonEpisodeCueSheetRecords?show_season_id=${this.state.showSeasonId}&show_season_episode_id=${this.state.showSeasonEpisodeId}&cue_sheet_header_id=${cueSheetHeaderId}`,
            this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(response => {
                let data = { ...response.data[0] }
                let config = { ...this.state.config }
                if (this.getEditPermission(data, this.props?.permissionArrCueSheets)) {
                    config.actions = ["pen", 'copy', 'times']
                }
                else {
                    config.actions = [];
                }
                config.disableSelectCheckbox = response.data?.length > 0 ? 
                                !this.getEditPermission(data, this.props?.permissionArrCueSheets)
                                 :
                                !this.props?.permissionArrCueSheets?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.edit === 1 
                if(data?.cue_sheet_records?.length > 0){
                    data?.cue_sheet_records?.sort((a, b) => {
                        const numericA = parseInt(a?.cue_no?.match(/^\d+/)?.[0] || '', 10);
                        const numericB = parseInt(b?.cue_no?.match(/^\d+/)?.[0] || '', 10);
                    
                        if (!isNaN(numericA) && !isNaN(numericB)) {
                        if (numericA !== numericB) {
                            return numericA - numericB;
                            }
                        } else if (!isNaN(numericA)) {
                            return -1;
                        } else if (!isNaN(numericB)) {
                            return 1;
                        }
                        return a?.cue_no?.localeCompare(b?.cue_no);
                    });
                    for(let i = 0 ; i < data?.cue_sheet_records?.length ; i++) {
                        data.cue_sheet_records[i].min = data.cue_sheet_records[i].duration  ? data.cue_sheet_records[i].duration?.split(':')[0] : null
                        data.cue_sheet_records[i].sec =  data.cue_sheet_records[i].duration  ? data.cue_sheet_records[i].duration?.split(':')[1] : null
                        data.cue_sheet_records[i].colon =  ':'
                        data.cue_sheet_records[i].editing = false
                        delete data.cue_sheet_records[i]?.duration 
                    }
                    let numberOfCues = data?.cue_sheet_records?.length - 1;
                    let lastRecordCueNo = data?.cue_sheet_records[numberOfCues]?.cue_no;
                    if (!isNaN(lastRecordCueNo)) {
                        this.setState({ currentUser: {cue_no: String(parseInt(lastRecordCueNo) + 1) ,colon : ':'  }})
                    }else {
                        this.setState({ currentUser: { colon : ':' }})
                    }
                } else {
                    this.setState({ currentUser: { colon : ':' }})
                }  
                this.setState({
                    cueSheetRecordData: response.data?.length > 0 ?  data : null,
                    page: 0,
                    config: config,
                    renderList: handlePaginationList(0, this.state.rowsPerPage, data?.cue_sheet_records),
                    isFetchingCueSheetDetails: false, isSubmit: false
                }, () => {
                    this.getCompositionList()
                })

            })
    }

    getRecurringCueDetails = () => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonRecurringCues?show_season_id=${this.state.showSeasonId}`, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(response => {
                let showSeasonRecurringCues = response?.data[0];
                this.setState({
                    showSeasonRecurringCues: showSeasonRecurringCues,
                    recurringList: showSeasonRecurringCues?.show_season_recurring_cue_list?.map(item => ({
                        label: item?.composition,
                        value: item?.show_season_recurring_cue_id,
                    }))
                })
            })
    }

    getCompositionList = () => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
        `/entitySearch?entity=${"CUE_SHEET_COMPOSITION"}&searchString=${this.state?.showSeasonEpisodeId}`, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
        .then(response => {
            let existingSongs = this.state.cueSheetRecordData?.cue_sheet_records?.map(item => item?.song_name);
            let compositionData = response.data
            ?.filter(item => item.status.split(",").includes("Using"))
            ?.map(item => ({
                label: item.song_title,
                value: item.song_id,
                composition: item.song_title
            }));
            let distinctOptions = compositionData?.filter(
                item => !existingSongs?.includes(item?.composition)
            );
            let cueSheetRecordConfig = { ...this.state.config };
            cueSheetRecordConfig.newRowSelectOptions[2].addRowOptions = distinctOptions;
            this.setState({ config: cueSheetRecordConfig });
        });
    }

    getComposerDetails = () => {
            ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
                `/entitySearch?entity=${"SHOW_SEASON_COMPOSERS"}&searchString=${this.props?.showSeasonId}`, this.props.userContext?.active_tenant?.tenant_id , this.props?.division_id)
                .then(response => {
                    let composerListOptions = response.data?.map(item => {
                        let newItem = { ...item }
                        newItem.label = item.composer,
                        newItem.value = item.show_season_composer_id
                        return newItem
                    })
                    let cueSheetRecordConfig = { ...this.state.config }
                    cueSheetRecordConfig.newRowSelectOptions[4].addRowOptions = composerListOptions
                    this.setState({ config: cueSheetRecordConfig, composerOptions: composerListOptions })
                })
        }

    getEntity = (entity, entityType, searchString) => {
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl +
            `/entitySearch?entity=${entityType}&searchString=${searchString}`, this.props?.userContext?.active_tenant?.tenant_id)
            .then(response => {
                let cueSheetConfig = { ...this.state.config }
                if (entityType === "MUSIC_USES") {
                    let musicUses = response?.data?.map(item => ({
                        text: item.abbreviation,
                        value: item.music_uses_id,
                    }));
                    cueSheetConfig.headings[6].selectOptions = musicUses
                    this.setState({config: cueSheetConfig })
                }
            })
    }



    formatCueSheetDetails = (dataItem, mode = null) => {
        this.setState({ mode: mode })
        let cueSheetDetails = { ...this.state.cueSheetDetails }
        cueSheetDetails.cue_sheet_header_id = this.state.selectedAiring || null
        cueSheetDetails.show_season_id = this.state.showSeasonId
        cueSheetDetails.show_season_episode_id = this.state.showSeasonEpisodeId
        cueSheetDetails.show_id = this.props.showId
        cueSheetDetails.season_id = this.props.seasonId
        cueSheetDetails.composer = dataItem?.composer?.toUpperCase() || null;
        cueSheetDetails.publisher = dataItem?.publisher?.toUpperCase() || null;
        cueSheetDetails.is_arr = dataItem?.is_arr ? 1 : 0;
        cueSheetDetails.min = dataItem?.min || null;
        cueSheetDetails.sec = dataItem?.sec || null;
        cueSheetDetails.how_used = mode === "add_recurring_cue" ? dataItem?.how_used : dataItem?.how_used?.value || null;
        cueSheetDetails.cue_sheet_record_id = dataItem?.cue_sheet_record_id ? dataItem?.cue_sheet_record_id : null;
        cueSheetDetails.cue_no = mode === "add_recurring_cue" ? cueSheetDetails.cue_no : mode === "copy" ? dataItem?.cue_no  ?  `${dataItem?.cue_no}-A`?.toUpperCase() : null : dataItem?.cue_no?.toUpperCase()|| null;
        cueSheetDetails.composition = dataItem?.composition?.toUpperCase() || null;
        if (mode === "delete") {
            cueSheetDetails.is_delete = 1;
        } else if (mode === "copy" || mode === "add_recurring_cue") {
            cueSheetDetails.cue_sheet_record_id = null;
        }
        return cueSheetDetails;
    }


    postCueSheetDetails = (payload, postFlag) => {
        this.setState({ isSubmit: postFlag})
        payload.duration = `${payload?.min ? payload.min : ""}:${payload.sec ? payload.sec : ""}`
        delete payload.min
        delete payload.sec
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl +
            `/showSeasonEpisodeCueSheetRecords`, payload, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(response => {
                if (response) {
                    if (response.data.error) {
                        this.setState({ isSubmit: false, openNotification: 'error' })
                    } else {
                        if(this.state.selectedAiring === null){
                            this.getCueSheetHeader()
                        }else{
                            this.getCueSheetDetails(this.state.selectedAiring,false);
                        }
                        this.setState({ openNotification: 'success' })
                    }
                }
            })
    }

    handleshowRecurringModal = () => {
        this.setState({ showRecurringModal: true })
    }

    handleHideRecurringModal = () => {
        this.setState({ showRecurringModal: false })
    }

    handleshowSeasonComposerModal = () => {
        this.setState({ showSeasonComposerModal: true })
    }

    handleHideShowSeasonComposerModal = () => {
        this.setState({ showSeasonComposerModal: false })
    }

    handleOpenEditDetails = (event) => {
        this.setState({ setModal: event.currentTarget })
    }

    

    handleStatus = (statusType, loaderType) => {
        let postJson = { ...initialStatusConfig };
        postJson.show_season_episode_id = this.state.showSeasonEpisodeId;
        postJson.cue_sheet_header_id = this.state.selectedAiring;
        postJson.status = statusType;
        this.setState({ [loaderType]: true })
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl + `/showSeasonEpisodeCueSheetStatus`,
            postJson, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(
                (response) => {
                    if (response.data.error) {
                        this.setState({ [loaderType]: false , openNotification:'error'})
                    } else {
                        this.setState({ [loaderType]: false , openNotification:'success'})
                        this.getCueSheetDetails(this.state.selectedAiring);
                        if(statusType === 'finalized') {
                            this.prviewPdf(1,null)
                        }
                    }
                },
                (err) => {
                    this.setState({ [loaderType]: false, openNotification:'error' })
                    
                }
            )
    }
    
    uploadXMLFile = () => {
        this.setState({isFinalized:true})
        ClearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/uploadXml?showSeasonEpisodeId=${this.state.showSeasonEpisodeId}&cue_sheet_header_id=${this.state.selectedAiring}`, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(response => {
                    if (response.data.error) {
                        this.setState({ isFinalized: false,openNotification:'error', anyErrorMessage:response?.data?.message })
                    } else {
                        this.handleStatus('finalized', 'isFinalized')
                    }
            },
                (err) => {
                    this.setState({ isFinalized: false ,openNotification:'error'})
                })
    }

    handleChangePage = (event, newPage) => {
        this.setState({
            page: newPage,
            renderList: handlePaginationList(newPage, this.state.rowsPerPage, this.state.cueSheetRecordData?.cue_sheet_records)
        });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0,
            renderList: handlePaginationList(0, parseInt(event.target.value, 10), this.state.cueSheetRecordData?.cue_sheet_records)
        });
    }

    handleAddRecurringCue = () => {
        let recurringCue = this.state.showSeasonRecurringCues?.show_season_recurring_cue_list?.find(item => item?.show_season_recurring_cue_id === this.state.selectedRecurringSong);
        let payload = this.formatCueSheetDetails(recurringCue, "add_recurring_cue");
        this.postCueSheetDetails(payload, 'newItemPost');
        this.setState({ selectedRecurringSong: null })
    }

    
    downloadUrl = (url) => {
        if (url) {
          fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
              const link = document.createElement("a");
              link.href = url;
              document.body.appendChild(link);
              link.click();
              link.remove();
            });
        }
    };

    prviewPdf = (is_box_upload = 1, loaderName = null) => {
        if(loaderName) {
            this.setState({[loaderName]: true})
        }
          let searchPayload = {
            show_season_id: this.state.cueSheetRecordData?.show_season_id,
            show_season_episode_id: this.props?.showSeasonEpisodeId,
            cue_sheet_header_id: this.state.selectedAiring,
            is_box_upload:is_box_upload,
          };
          Object.keys(searchPayload).map((obj) => {
            if (searchPayload[obj] === undefined) {
              searchPayload[obj] = null;
            }
          });
          let encryptTedStr = btoa(JSON.stringify(searchPayload));
          let load = {
            payload: "exportPdf",
            reportName: "rptCueSheetsReport",
            fileName: "cueSheetsReport",
            searchJson: encryptTedStr,
            showHtml: 0,
            encryptionType: 1,
            tenantId: this.props?.userContext?.active_tenant?.tenant_id,
            divisionId: this.props?.division_id,
          };
          ClearTrackService.getDataWS(load).then(
            (webSocketConn) =>
              (webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data);
                if (response?.statusCode == 200) {
                    if(loaderName) {
                        this.setState({[loaderName]: false})
                    }
                    if(is_box_upload === 0) {
                        this.downloadUrl(response?.body);
                    }
                    if(loaderName === 'isNewCSLoader') {
                        this.setState({openNotification:'success'})
                    }   
                }
              })
          );
    } 
      
    getEditPermission (data, permisssion)  {
        if(!data?.status) {
            return permisssion?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.edit === 1
        } else {
            if(data?.status === 'submitted') {
                return permisssion?.find(item => item?.permission_name === 'Cue Sheet Access - Submitted')?.edit === 1 
            } else if(data?.status === 'approved') {
                return permisssion?.find(item => item?.permission_name === 'Cue Sheet Access - Approved')?.edit === 1 
            } else {
                return false
            }
        }
    }

    getEditDetailsPermission (data, permisssion)  {
        if(!data?.status) {
            return permisssion?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.edit === 1
        } else {
            if(data?.status === 'submitted') {
                return permisssion?.find(item => item?.permission_name === 'Cue Sheet Access - Submitted')?.edit === 1 
            } else if(data?.status === 'approved') {
                return permisssion?.find(item => item?.permission_name === 'Cue Sheet Access - Approved')?.edit === 1 
            } else if(data?.status === 'finalized') {
                return permisssion?.find(item => item?.permission_name === 'Cue Sheet Access - Finalized')?.edit === 1 
            } else {
                return false
            }
        }
    }

    getCueSheetDefaultDataPermission () {
        let accessableRole = ['Super Admin', 'Music Clearance (Admin)', 'Music Clearance (Basic)', 'Music Clearance (Regular User)', 'Line Producer', 'Music Editor/Supervisor']?.includes(this.state.divisionData?.role_name)
        return this.state.cueSheetRecordData ? (!this.state.cueSheetRecordData?.status && accessableRole) : accessableRole
    }

    getUpdatePubInfoPermission () {
        if(this.state.cueSheetRecordData?.status === 'submitted') {
            return ['Super Admin', 'Music Clearance (Admin)', 'Music Clearance (Basic)', 'Music Clearance (Regular User)','Line Producer']?.includes(this.state.divisionData?.role_name)
        } else if(this.state.cueSheetRecordData?.status === 'approved') {
            return ['Super Admin', 'Music Clearance (Admin)', 'Music Clearance (Basic)', 'Music Clearance (Regular User)']?.includes(this.state.divisionData?.role_name)
        } else {
            return false
        }
    }

    getNewCSPermission () {
        if(this.state.cueSheetRecordData?.status === 'submitted') {
            return ['Super Admin', 'Music Clearance (Admin)', 'Music Clearance (Basic)', 'Music Clearance (Regular User)','Line Producer']?.includes(this.state.divisionData?.role_name)
        } else if(this.state.cueSheetRecordData?.status === 'approved') {
            return ['Super Admin', 'Music Clearance (Admin)', 'Music Clearance (Basic)', 'Music Clearance (Regular User)']?.includes(this.state.divisionData?.role_name)
        } else if(this.state.cueSheetRecordData?.status === 'finalized') {
            return ['Super Admin', 'Music Clearance (Admin)', 'Music Clearance (Basic)', 'Music Clearance (Regular User)']?.includes(this.state.divisionData?.role_name)
        } else {
            return ['Super Admin', 'Music Clearance (Admin)', 'Music Clearance (Basic)', 'Music Clearance (Regular User)','Line Producer', 'Music Editor/Supervisor']?.includes(this.state.divisionData?.role_name)
        }
    }

    UpdatePubInfo = () => {
        let payload = {
            "show_season_episode_id": this.props?.showSeasonEpisodeId,
            "cue_sheet_header_id":this.state.selectedAiring
        }
        this.setState({isUpdatePubInfo: true})
        ClearTrackService.postDataParams(Constants.ClearTrackServiceBaseUrl +
            `/cueSheetWriterPublisher`, payload, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
            .then(response => {
                if (response) {
                    if (response.data.errr) {
                        this.setState({ isUpdatePubInfo: false, openNotification: 'error' })
                    } else {
                        this.getCueSheetDetails(this.state.selectedAiring,false);
                        this.setState({ openNotification: 'success', isUpdatePubInfo: false,  })
                    }
                }
            }),
            (err) => {
                this.setState({ isUpdatePubInfo: false, openNotification: 'error' })
            }
    }

    editClicked = (id, list) => {
        let renderList = list.map((item) => {
            if (item.cue_sheet_record_id == id) {
                let use_id = item?.how_used
                item['how_used'] = {text: item?.uses, value: item?.how_used}
                item['uses'] = use_id
                item['editing'] = true
            } else {
                item.editing = false
            }
            return item
        })
        this.setState({ renderList: renderList });
    }

    render() {
        return (
            <>
                <NotificationComponent
                    open={this.state.openNotification?.length > 0 ? true : false}
                    message={this.state.anyErrorMessage ? this.state.anyErrorMessage  : this.state.openNotification === 'success' ? 'Changes saved sucessfully' : this.state.openNotification === 'error' ? 'Saving Changes failed' : ''}
                    severity={this.state.openNotification || ''}
                    handleResetNotify={() => this.setState({ openNotification: null ,anyErrorMessage : null})}
                />
                <div className="cueSheet row" id='mainGrid'>
                    <Accordion onChange={this.handleChange('panel1')}>
                        <div className="cueSheet-header">
                            <div className="cuesheet-btn">
                                <Typography className="header-cue" sx={{ width: '3%', flexShrink: 0, color: 'text.black', fontSize: '10px' }}>
                                    Cue Sheet
                                </Typography>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                >
                                </AccordionSummary>
                                {this.state.expanded  &&
                                    <div onClick={this.handleshowRecurringModal} className="label-with-cursor">
                                        <span> Recurring Cues</span>
                                    </div>
                                }
                                {this.state.expanded  &&
                                    <div onClick={this.handleshowSeasonComposerModal} className="label-with-cursor">
                                        <span> Show Season Composer</span>
                                    </div>
                                }
                                {this.state.expanded &&<div  className="cue-sheet-record-status">
                                    {this.state.cueSheetRecordData?.is_submit  && <span>Cue Sheet is pending (not yet Submitted)</span>}
                                    {this.state.cueSheetRecordData?.status === 'finalized'  && <span>Cue Sheet finalized by {this.state.cueSheetRecordData?.finalized_by} on {this.state.cueSheetRecordData?.finalized_date}</span>}
                                    {(this.state.cueSheetRecordData?.status === 'submitted' ) && <span>Cue Sheet submitted by {this.state.cueSheetRecordData?.submitted_by} on {this.state.cueSheetRecordData?.submitted_date}</span>}
                                    {(this.state.cueSheetRecordData?.status === 'approved' ) && <span>Cue Sheet approved by {this.state.cueSheetRecordData?.approved_by} on {this.state.cueSheetRecordData?.approved_date}</span>}
                                </div>}
                            </div>
                            {this.state.expanded &&<MDBRow className='cuesheet-btn-3'>
                            <MDBCol className='flex justify-content-between'>
                                {this.state.cueSheetRecordData?.rc_xml_cue_id &&  this.state.cueSheetRecordData?.status === 'finalized' && 
                                 ['Support','Super Admin', 'Music Clearance (Admin)', 'Music Clearance (Basic)', 'Music Clearance (Regular User)']?.includes(this.state.divisionData?.role_name) &&
                                    <BasicButton
                                        variant="outlined"
                                        type="inline"
                                        color="primary"
                                        text={"XML Ack."}
                                        onClick={() => {this.setState({ showXmlAckModal: true })}}
                                />}
                                { (this.state.selectedAiring ? (this.state?.cueSheetRecordData ? this.getEditPermission(this.state.cueSheetRecordData, this.props?.permissionArrCueSheets) :  this.props?.permissionArrCueSheets?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.edit === 1 ) : this.props?.permissionArrCueSheets?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.edit === 1 )  &&
                                    <BasicButton
                                        color="primary"
                                        text={this.state.isImport ? <CircularProgress color="inherit" size={20} /> : "Import"}
                                        id={'imporstCueSheetRecord'}
                                        variant="contained"
                                        type="inline"
                                        onClick={() => this.setState({showImportCueSheet:true})}
                                />}
                                {this.state.cueSheetRecordData?.cue_sheet_records?.length > 0 && 
                                <BasicButton
                                    className="cuesheet-btn-2 primary-btn"
                                    variant="outlined"
                                    type="inline"
                                    color="primary"
                                    text={this.state.isPreviewLoader ? <CircularProgress color="inherit" size={20} /> :"Preview"}
                                    onClick={() => this.prviewPdf(0, 'isPreviewLoader')}
                                />}
                                {this.state.cueSheetRecordData?.cue_sheet_records?.length > 0 &&  this.getUpdatePubInfoPermission() &&<BasicButton
                                    variant="contained"
                                    type="inline"
                                    color="primary"
                                    text={this.state.isUpdatePubInfo ? <CircularProgress color="inherit" size={20} /> : "Update w/pub info"}
                                    onClick={this.UpdatePubInfo}
                                />}
                                {this.state.cueSheetRecordData?.cue_sheet_records?.length > 0  &&  this.getNewCSPermission()&&<BasicButton
                                    variant="outlined"
                                    type="inline"
                                    color="primary"
                                    text={this.state.isNewCSLoader ? <CircularProgress color="inherit" size={20} /> : "New CS"}
                                    onClick={() => this.prviewPdf(1, 'isNewCSLoader')}
                                />}
                            </MDBCol>
                            </MDBRow>}
                        </div>
                        {this.state.expanded &&
                            <div className="d-flex">
                                <div className="cue-sheet-header-form sectionWidth">
                                    <MDBRow>
                                        <MDBCol md={2}>
                                        <SelectField
                                                    id={"recurring-cue"}
                                                    label="Airing"
                                                    options={this.state.airingList?.length > 0 ? this.state.airingList : [{ label: 'Initial', value: 'Initial' }]}
                                                    value={this.state.airingList?.length > 0 ? this.state.selectedAiring : 'Initial'}
                                                    onChange={(e) => {
                                                        if (e.target.value !== 'Initial') {
                                                            this.getCueSheetDetails(e.target.value)
                                                            this.setState({ selectedAiring: e.target.value, cueSheetHeaderDetails: this.state.headingsData?.find(item => item?.cue_sheet_header_id === e.target.value) })
                                                        }
                                                    }}
                                                />
                                        </MDBCol>
                                        { this.props?.permissionArrCueSheets?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.edit === 1 &&
                                                <MDBCol md={1} className="copy-btn p-0">
                                                    {this.state.isFetchingVersionDetails ? <CircularProgress color="inherit" size={18} /> :
                                                        <BasicButton
                                                            id={this.state.showSeasonEpisodeId + this.state.selectedAiring}
                                                            variant="contained"
                                                            text="Copy"
                                                            type="inline"
                                                            color="primary"
                                                            disabled={this.state.cueSheetRecordData?.cue_sheet_records?.length === 0 || this.state.cueHeaderLength === 0 ? true : false}
                                                            onClick={(e) => this.setState({ setCopyModal: e.currentTarget })}
                                                        />
                                                    }
                                                    <Popover
                                                        id={'copy' + this.state.showSeasonEpisodeId + this.state.selectedAiring}
                                                        open={this.state.setCopyModal ? true : false}
                                                        className={"pop-over"}
                                                        anchorEl={this.state.setCopyModal}
                                                        onClose={() => this.setState({ setCopyModal: null })}
                                                        anchorReference="anchorOrigin"
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'left',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'left',
                                                        }}
                                                        PaperProps={{ style: { width: '25%' } }}
                                                    >
                                                        <CueSheetCopyVersion
                                                            show_season_id={this.state.showSeasonId}
                                                            show_season_episode_id={this.state.showSeasonEpisodeId}
                                                            show_id={this.props?.showId}
                                                            season_id={this.props?.seasonId}
                                                            cueSheetHeaderDetails={this.state.cueSheetHeaderDetails}
                                                            cue_sheet_header_id={this.state.selectedAiring}
                                                            handleClose={() => this.setState({ setCopyModal: null })}
                                                            availableAiring={this.state.airingList?.map(item => item?.label)}
                                                            division_id={this.props.division_id}
                                                            notificationComponent={(value, msg=null) => this.setState({ openNotification: value, anyErrorMessage: msg })}
                                                            toggleRefresh={() => this.getCueSheetHeader()}
                                                        />
                                                    </Popover>
                                                </MDBCol>
                                            }
                                        {this.props?.permissionArrCueSheets?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.view === 1 && <MDBCol md={2} className="p-0">
                                            <BasicLabel text={'Time'} />
                                            <span className="basic-text">
                                                 {this.state.cueSheetHeaderDetails?.cue_sheet_time || "-"}
                                            </span>
                                        </MDBCol>}
                                        {<MDBCol>
                                        {this.state.cueSheetHeaderDetails?.is_send_through_rapid_cue ? this.props?.permissionArrCueSheets?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.view === 1 &&
                                                <MDBRow
                                                    md={`${!this.state.cueSheetHeaderDetails?.is_show_episode_date ? 2 : ''}`}
                                                    xl={`${!this.state.cueSheetHeaderDetails?.is_show_episode_date ? 4 : ''}`}
                                                    className={`${(this.state.cueSheetHeaderDetails?.is_send_through_rapid_cue &&
                                                        this.state.cueSheetHeaderDetails?.is_show_episode_date) ? 'rapid-cue' : ''
                                                        } `
                                                    }
                                                >
                                                    <div className="d-flex" style={{ marginLeft: '45px'}}>
                                                        <BasicLabel text={"Send through RapidCue"} />
                                                        <MDBIcon icon="check-circle" size="sm"  style={{ color: '#228975', fontSize: '0.65rem', marginLeft: '0.3rem', lineHeight: 2.5  }} />
                                                    </div>
                                                </MDBRow> : null
                                            }
                                       {this.state.cueSheetHeaderDetails?.is_show_episode_date ? 
                                                this.props?.permissionArrCueSheets?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.view === 1 && <MDBRow
                                                    md={`${!this.state.cueSheetHeaderDetails?.is_send_through_rapid_cue ? 3 : ''}`}
                                                    className={`${(this.state.cueSheetHeaderDetails?.is_send_through_rapid_cue &&
                                                        this.state.cueSheetHeaderDetails?.is_show_episode_date) ? 'date-time' : ''
                                                        } `
                                                    }
                                                >
                                                    <div className="d-flex" style={{ marginLeft: '60px'}}>
                                                        <BasicLabel text={"Show Episode Date/Times on Cue Sheet"} />
                                                        <MDBIcon icon="check-circle" size="sm"  style={{ color: '#228975', fontSize: '0.65rem', marginLeft: '0.3rem', lineHeight: 2.5  }} />
                                                    </div>
                                                </MDBRow>: null
                                        } </MDBCol> }
                                        {this.props?.permissionArrCueSheets?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.view === 1 && <MDBCol md={1} className="p-0 notes-file-size">
                                                <BasicLabel text={'Notes'} />
                                                <MDBTooltip
                                                    tag="a"
                                                    title={this.state.cueSheetHeaderDetails?.cue_sheet_notes}
                                                    placement="right"
                                               >
                                                    <MDBIcon icon="file" size='sm' />
                                                </MDBTooltip>
                                            </MDBCol>}
                                            { this.state.cueSheetRecordData?.cue_sheet_records?.length > 0  && this.props?.permissionArrCueSheets?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.view === 1  && 
                                                <MDBCol md={2} className="edit-details-btn p-0">
                                                    <span>
                                                        <BasicButton
                                                            variant="contained"
                                                            text={this.state.selectedAiring ? this.state.cueSheetRecordData ? 
                                                                (this.getEditDetailsPermission(this.state.cueSheetRecordData, this.props?.permissionArrCueSheets)) ? 'Edit Details' : 'View Details' : 'Edit Details' :  'Edit Details'}
                                                            type="inline"
                                                            color="primary"
                                                            onClick={this.handleOpenEditDetails}
                                                        />
                                                        <Popover
                                                            id={"edit-detail"}
                                                            open={this.state.setModal ? true : false}
                                                            className={"pop-over"}
                                                            anchorEl={this.state.setModal}
                                                            onClose={() => this.setState({ setModal: null })}
                                                            anchorReference="anchorOrigin"
                                                            anchorOrigin={{
                                                                vertical: 'bottom',
                                                                horizontal: 'left',
                                                            }}
                                                            transformOrigin={{
                                                                vertical: 'top',
                                                                horizontal: 'left',
                                                            }}
                                                            PaperProps={{ style: { width: '25%' } }}
                                                        >
                                                            <CueSheetEditDetails
                                                                show_season_id={this.state.showSeasonId}
                                                                show_season_episode_id={this.state.showSeasonEpisodeId}
                                                                show_id={this.props?.showId}
                                                                season_id={this.props?.seasonId}
                                                                cueSheetHeaderDetails={this.state.cueSheetHeaderDetails}
                                                                cue_sheet_header_id={this.state.selectedAiring}
                                                                disabledData={this.state.selectedAiring ?  !this.state.cueSheetRecordData ? false : !this.getEditDetailsPermission(this.state.cueSheetRecordData, this.props?.permissionArrCueSheets) : false}
                                                                handleClose={() => this.setState({ setModal: null })}
                                                                toggleRefresh={this.getCueSheetDetails}
                                                                getCueSheetHeader={this.getCueSheetHeaderId.bind(this)}
                                                                division_id={this.props.division_id}
                                                                musicEditorView={(this.state.divisionData?.role_name === 'Music Editor/Supervisor' || this.state.divisionData?.role_name === 'Line Producer' || (this.state.cueSheetRecordData?.status === 'finalized' &&  ['Super Admin', 'Music Clearance (Admin)', 'Music Clearance (Basic)', 'Music Clearance (Regular User)']?.includes(this.state.divisionData?.role_name)))}
                                                                notificationComponent={(value) => this.setState({ openNotification: value })}
                                                            />
                                                        </Popover>
                                                    </span>

                                                </MDBCol>
                                            }
                                    </MDBRow>
                                   
                                </div>
                                <div className='status-section-width'>
                                        <MDBRow className='submit-margin-top'>
                                            {
                                                <>
                                                    {
                                                        this.state.cueSheetRecordData?.is_submit && this.props.permissionArrCueSheets?.find(item => item?.permission_name === 'Submit (when Initialized)')?.view === 1 &&
                                                        <MDBCol md={2} xl={2} className="p-0 ml-2">
                                                            <BasicButton
                                                                className="submit-btn"
                                                                variant="contained"
                                                                type="inline"
                                                                color="primary"
                                                                text={this.state.isSubmitted ? <CircularProgress color="inherit" size={18} /> : "Submit"}
                                                                onClick={() => this.handleStatus('submitted', 'isSubmitted')}
                                                            />
                                                        </MDBCol>
                                                    }
                                                </>
                                            }
                                            {this.state.cueSheetRecordData?.is_unsubmit  && this.props.permissionArrCueSheets?.find(item => item?.permission_name === 'Un-submit button (when Submitted)')?.view === 1 &&
                                                <MDBCol md={3} xl={3} className="p-0 ml-1">
                                                    <BasicButton
                                                        className="unsubmit-btn"
                                                        variant="outlined"
                                                        type="inline"
                                                        text={this.state.isUnSubmitted ? <CircularProgress color="inherit" size={18} /> : "Un-Submit"}
                                                        onClick={() => this.handleStatus('unsubmitted', 'isUnSubmitted')}
                                                    />
                                                </MDBCol>
                                            }
                                            {this.state.cueSheetRecordData?.is_approve && this.props.permissionArrCueSheets?.find(item => item?.permission_name === 'Approve button (when Submitted)')?.view === 1 &&
                                                <MDBCol md={1} xl={1} className="p-0 space-check-approve">
                                                    <BasicButton
                                                        className="submit-btn"
                                                        variant="contained"
                                                        type="inline"
                                                        color="primary"
                                                        text={this.state.isApproved ? <CircularProgress color="inherit" size={18} /> : "Approve"}
                                                        onClick={() => {
                                                         this.handleStatus("approved", 'isApproved')
                                                        }} />
                                                </MDBCol>
                                            }
                                            {this.state.cueSheetRecordData?.is_unapprove  &&  this.props.permissionArrCueSheets?.find(item => item?.permission_name === 'Un-Approve button (when Approved)')?.view === 1 &&
                                                <MDBCol md={4} xl={4} className="p-0 space-check-approve">
                                                    <BasicButton
                                                        className="un-finalize-btn"
                                                        variant="outlined"
                                                        type="inline"
                                                        color="primary"
                                                        text={this.state.isApproved ? <CircularProgress color="inherit" size={18} /> : "Un-Approve"}
                                                        onClick={() => {
                                                         this.handleStatus("unapproved", 'isUnApproved')
                                                        }} />
                                                </MDBCol>
                                            }
                                            {
                                                <>
                                                    {this.state.cueSheetRecordData?.is_finalize  && (this.props.permissionArrCueSheets?.find(item => item?.permission_name === 'Finalize buttons (when Approved)')?.view === 1 || 
                                                    this.props.permissionArrCueSheets?.find(item => item?.permission_name === 'Finalize button (when Submitted)')?.view === 1) &&
                                                        <MDBCol
                                                            md={2} xl={2}
                                                            className={`${!this.state.cueSheetRecordData?.is_unsubmit ? 'ml-2' : ''} p-0`}
                                                        >
                                                            <BasicButton
                                                                className="finalize-btn"
                                                                variant="contained"
                                                                type="inline"
                                                                color="primary"
                                                                text={this.state.isFinalized ? <CircularProgress color="inherit" size={18} /> : "Finalize"}
                                                                onClick={this.uploadXMLFile}
                                                            />
                                                        </MDBCol>
                                                    }
                                                </>
                                            }
                                            {this.state.cueSheetRecordData?.is_unfinalize && this.props.permissionArrCueSheets?.find(item => item?.permission_name === 'Un-Finalize button (when Finalized)')?.view === 1 &&
                                                <MDBCol md={4} xl={4} className="p-0 ml-2">
                                                    <BasicButton
                                                        className="un-finalize-btn"
                                                        variant="outlined"
                                                        type="inline"
                                                        text={this.state.isUnFinalized ? <CircularProgress color="inherit" size={18} /> : "Un-Finalize"}
                                                        onClick={() => this.handleStatus('unfinalized', 'isUnFinalized')}
                                                    />
                                                </MDBCol>
                                            }
                                        </MDBRow>
                                    </div>
                               
                            </div>
                        }
                        <AccordionDetails>
                        {this.state.isFetchingCueSheetDetails ?
                            <div className="ContentLoader">
                                <CircularProgress />
                            </div> :
                            <MDBRow className={"cueSheetTable"} id="episode-clearance-table">
                                <CuesheetTableComponet
                                    renderList={this.state.renderList}
                                    config={this.state.config}
                                    formatCueSheetDetails={this.formatCueSheetDetails.bind(this)}
                                    postCueSheetDetails={this.postCueSheetDetails}
                                    cueHeaderLength={this.state.cueHeaderLength}
                                    showSeasonEpisodeCueSheetRecords={this.state.cueSheetRecordData}
                                    composerDetalis={this.state.composerDetalis}
                                    composerOptions={this.state.composerOptions}
                                    CuesheetDashboard={false}
                                    isSubmit={this.state.isSubmit}
                                    showSeasonId={this.state.showSeasonId}
                                    showSeasonEpisodeId={this.state.showSeasonEpisodeId}
                                    show_id={this.props?.showId}
                                    season_id={this.props?.seasonId}
                                    currentUser={this.state.currentUser}
                                    addItemToList={this.state.selectedAiring ? this.state.cueSheetRecordData ? this.getEditPermission(this.state.cueSheetRecordData,this.props?.permissionArrCueSheets) : this.props?.permissionArrCueSheets?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.edit === 1 && this.getEditPermission(this.state.cueSheetRecordData,this.props?.permissionArrCueSheets) : this.props?.permissionArrCueSheets?.find(item => item?.permission_name === 'Cue Sheet Access - Initialized')?.edit === 1 }
                                    editClicked = {this.editClicked.bind(this)}
                                />
                                <MDBRow className="pagination-section">
                                    {(this.state.selectedAiring ?
                                         (this.state.cueSheetRecordData ? this.getEditPermission(this.state.cueSheetRecordData,this.props?.permissionArrCueSheets) : true) && 
                                         this.state.recurringList?.length > 0 : this.state.recurringList?.length > 0) ? 
                                         <MDBCol className="recurring-cue">
                                        <MDBRow>
                                            <MDBCol md={5}>
                                                <SelectField
                                                    id={"recurring-cue"}
                                                    defaultMenuText={"- Add a Recurring Cue -"}
                                                    options={this.state.recurringList || []}
                                                    value={this.state.selectedRecurringSong || ""}
                                                    onChange={(e) => this.setState({ selectedRecurringSong: e.target.value })}
                                                />
                                            </MDBCol>
                                                <MDBCol className="p-0 chevron">
                                                    <MDBIcon
                                                        icon={"plus-circle"}
                                                        onClick={this.handleAddRecurringCue}
                                                    />
                                                </MDBCol>
                                        </MDBRow>
                                    </MDBCol> : <MDBCol md={7} className="recurring-cue"></MDBCol>}
                                    <MDBCol md={5}>
                                        <TablePagination
                                            component="div"
                                            count={this.state.cueSheetRecordData?.cue_sheet_records ? this.state.cueSheetRecordData?.cue_sheet_records?.length : 0}
                                            page={this.state.page}
                                            onChangePage={this.handleChangePage}
                                            rowsPerPage={this.state.rowsPerPage}
                                            rowsPerPageOptions={[250, 300, 500, 1000]}
                                            onChangeRowsPerPage={this.handleChangeRowsPerPage}
                                        />
                                    </MDBCol>
                                </MDBRow>
                            </MDBRow>
                        }
                        </AccordionDetails>
                    </Accordion>
                    {this.state.showImportCueSheet &&
                            <MainViewModalComponent
                                open={this.state.showImportCueSheet}
                                handleClose={() => this.setState({ showImportCueSheet: false })}
                                headerText={"Import Cue Sheet Records"}
                                mode={''}
                                divisionId={this.props.division_id}
                                modalName={"Import Cue Sheet Data"}
                                EpId={this.state.showSeasonEpisodeId}
                                airingType={this.state.selectedAiring ? this.state.airingList?.find(item => item?.value === this.state.selectedAiring)?.label : 'Initial'}
                                handleSubmit={() => { 
                                    if(this.state.selectedAiring) {
                                        this.getCueSheetDetails(this.state.selectedAiring) }
                                     else {
                                        this.getCueSheetHeader()
                                     }
                                    }}
                            />
                    }
                    {this.state.showRecurringModal &&
                        <MainViewModalComponent
                            open={this.state.showRecurringModal}
                            handleClose={this.handleHideRecurringModal}
                            headerText={"Recurring Cue for ".concat(this.props.show_name + ' ' + this.props.season_name)}
                            mode={""}
                            modalName={"RecurringCue"}
                            showSeasonId={this.props?.showSeasonId}
                            showId={this.props?.showId}
                            seasonId={this.props?.seasonId}
                            toggleRefresh={this.getRecurringCueDetails}
                            showSeasonEpisodeId={this.state.showSeasonEpisodeId}
                            division_id={this.props?.division_id}
                            composerOptions={this.state.composerOptions || []}
                        />
                    }
                    {this.state.showXmlAckModal &&
                        <MainViewModalComponent
                            open={this.state.showXmlAckModal}
                            handleClose={() => this.setState({ showXmlAckModal: false })}
                            headerText={"Rapid Cue Acknowledgements"}
                            mode={""}
                            modalName={"Rapid Cue Acknowledgements"}
                            showSeasonEpisodeCueSheetRecords={this.state.cueSheetRecordData}
                            division_id={this.props?.division_id}
                        />
                    }
                    {this.state.showComposerModal &&
                        <MainViewModalComponent
                            open={this.state.showComposerModal}
                            handleClose={() => this.setState({ showComposerModal: false })}
                            headerText={"Composer"}
                            mode={this.state.mode}
                            modalName={"Add Composer"}
                            handleNodeValue={(value) => this.setState({
                                composerName: {
                                    value: value,
                                    node: this.state.nodeName,
                                    index: this.state.cueSheetItemIndex
                                }
                            })}
                        />
                    }
                    {this.state.showPublisherModal &&
                        <MainViewModalComponent
                            open={this.state.showPublisherModal}
                            handleClose={() => this.setState({ showPublisherModal: false })}
                            headerText={"Publisher"}
                            mode={this.state.mode}
                            modalName={"Add Publisher"}
                            handleNodeValue={(value) => this.setState({
                                publisherName: {
                                    value: value,
                                    node: this.state.nodeName,
                                    index: this.state.cueSheetItemIndex
                                }
                            })}
                        />
                    }
                     {this.state.showSeasonComposerModal &&
                        <MainViewModalComponent
                            open={this.state.showSeasonComposerModal}
                            handleClose={this.handleHideShowSeasonComposerModal}
                            headerText={"Show Composer for " .concat(this.props.show_name + ' ' + this.props.season_name)}
                            mode={""}
                            modalName={"ShowSeasonComposer"}
                            showSeasonId={this.props?.showSeasonId}
                            showId={this.props?.showId}
                            seasonId={this.props?.seasonId}
                            toggleRefresh={this.getComposerDetails}
                            division_id={this.props?.division_id}
                        />
                    }
                </div>
            </>
        );
    }
});