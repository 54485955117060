import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import BasicCheckbox from "../../../components/SharedComponents/BasicCheckbox/BasicCheckbox";
import SelectField from "../../../components/SharedComponents/SelectField/SelectField";
import BasicLabel from "../../../components/SharedComponents/BasicLabel/BasicLabel";
import BasicButton from "../../../components/SharedComponents/BasicButton/BasicButton";
import { withUserContext } from "../../../contexts/UserContext";
import BasicTextArea from "../../SharedComponents/BasicTextArea/BasicTextArea";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { CircularProgress } from "@material-ui/core";
import {
  builtLettersOptions,
  licensorOptions,
  initialLettersData,
} from "./config";
import SearchSelectField from "../../../components/SharedComponents/SearchSelectField";
import Tabs from "../../SharedComponents/Tabs/Tabs";
import "./Letters.scss";
import clearTrackService from "../../../services/service";
import * as Constants from "../../../constants/constants";
import messages from '../../../Common/Messages.json';
import MainViewModalComponent from "../../../Common/MainViewModalLayout/Modal/Modal";
import MessageModal from '../../SharedComponents/MessageModal';
import NotificationComponent from "../../../Common/NotificationComponent/NotificationComponent"
import { getFormattedDate } from "../../../Common/Helper";

var letterCount = 0;

export default withUserContext(
  class Letters extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        builtLettersOption: builtLettersOptions,
        selectedLetter: "",
        licensorOption: licensorOptions,
        licensorOptionList: this.props?.licensorListData,
        licensorLetterData: initialLettersData,
        tabValue: null,
        tabLabel: "",
        lettersTabList: [],
        quoteRequestOptionList: [],
        finalArray: [],
        response: [],
        isMainTitle: this.props?.mainTitle,
        userDefinedLanguageIds: [],
        showSeasonId: this.props?.showSeasonId,
        showId: this.props?.showId,
        seasonId: this.props?.seasonId,
        songId: this.props?.song?.song_id,
        showSeasonEpisodeId: this.props?.showSeasonEpisodeId,
        licensorInfor: this.props?.clearneceLicenserInfo,
        values: [],
        postInitiated: false,
        isPosting: false,
        signatureOptions: [],
        writeLettersPermission: this.props?.lettersPermission?.edit,
        writeLQCPermissions: this.props?.lQCPermissions?.edit,
        statusNotInUsingAlert: false,
        showSuccessMessage: false,
        showFailMessage: false,
        count: 0,
        clearanceCOAOption:false,
        openQuoteRequestMessageModal:false,
      };
    }
    componentDidMount() {
      let formatList = this.props.licensorListData.map((item) => ({
        value: item.licensor_id,
        text: item.licensor_name,
        clearance_licensor_id :  this.props?.mainTitle === 'clearence' ? item?.clearance_licensor_id : item?.main_title_licensor_id,
        licensor_type : item.licensor_type
      }));
      this.setState({ licensorOptionList: formatList , clearanceCOAOption : this.props?.mainTitle === 'clearence' && this.props?.licensorsInfo[0]?.songs_list.some(item => item?.is_coa === 1)});

      let licensorLetterDataInitial = this.state.licensorLetterData
      licensorLetterDataInitial.licensor_list = [
        {
          value: this.props?.lic?.licensor_id,
          text: this.props?.lic?.licensor_name,
          licensor_type: this.props?.lic?.licensor_type,
          clearance_licensor_id: this.props?.mainTitle === 'clearence' ? this.props?.lic?.clearance_licensor_id : this.props?.lic?.main_title_licensor_id
        },
      ]
      licensorLetterDataInitial.licenseCheck = "N/A";
      licensorLetterDataInitial.noticeOfUseCheck = "N/A";
      licensorLetterDataInitial.licenseRequestCheck = "N/A";
      this.setState({ licensorLetterData: licensorLetterDataInitial }, () => {
        let status = this.props?.licensorListData.find(item => item.licensor_id === this.state.licensorLetterData?.licensor_list[0]?.value)?.status
        this.setState({ status: status })
      });
      this.getData();
      this.getData1();
      this.getData2();
      this.getuseConfirmationData();
      this.getData3();
      this.getData4();
      this.getSignatureData();
    }

    getSignatureData = () => {
      clearTrackService.getData(
        Constants.ClearTrackServiceBaseUrl +
        '/userAll', this.props.userContext?.active_tenant?.tenant_id, null
      ).then((response) => {
        let validRoles = ['Super Admin', 'Music Clearance (Admin)', 'Music Clearance (Regular User)', 'Music Clearance (Basic)', 'Licensing Admin']
        let validUsers = []
        for (let i = 0; i < response?.data?.length; i++) {
          for (let j = 0; j < response?.data[i]?.tenants?.length; j++) {
            if (response?.data[i]?.tenants[j]?.tenant_id === this.props.userContext?.active_tenant?.tenant_id) {
              for (let k = 0; k < response?.data[i]?.tenants[j]?.division_role?.length; k++) {
                // if (response?.data[i]?.tenants[j]?.division_role[k]?.division_id === this.props.division_id) {
                if (validRoles?.includes(response?.data[i]?.tenants[j]?.division_role[k]?.role_name)) {
                  validUsers.push({
                    value: response?.data[i].user_id,
                    label: response?.data[i].full_name,
                    is_active: response?.data[i].is_active,
                  })
                }
                // }
              }
            }
          }
        }
        this.setState({ signatureOptions: validUsers }, () => {
          let licensorLetterDataInitial = this.state.licensorLetterData
          licensorLetterDataInitial.coverLetterSignature = validUsers?.find(item => item.label === this.props?.userContext?.user_profile?.full_name)?.value
          licensorLetterDataInitial.licenseCoverLetterLetterType = "WB Internal - Countersignature"
          this.setState({ licensorLetterData: licensorLetterDataInitial })
        })

      });

    };

    getData = () => {
      clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/userDefinedLanguage?letter_title=Quote%20Request&show_season_id=${parseInt(this.state.showSeasonId)}`, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
        .then(response => {
          let formattedList = response.data?.map(item => ({ value: item.show_season_letter_language_id ? item.show_season_letter_language_id : item.letter_language_id, text: item.text_type, show_season_id: item.show_season_letter_language_id ? 1 : 0 }));
          this.setState({ quoteRequestOptionList: formattedList  });
        },
          (err) => {
            console.log("Error in fetching Email Types:", err)
          })

    }

    getData1 = () => {
      clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/userDefinedLanguage?letter_title=License%20Request&show_season_id=${parseInt(this.state.showSeasonId)}`, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
        .then(response => {
          let formattedList = response.data?.map(item => ({ value: item.show_season_letter_language_id ? item.show_season_letter_language_id : item.letter_language_id, text: item.text_type, show_season_id: item.show_season_letter_language_id ? 1 : 0 }));
          this.setState({ confirmationOptionList: formattedList });
        },
          (err) => {
            console.log("Error in fetching Email Types:", err)
          })
    }

    getuseConfirmationData = () => {
      clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/userDefinedLanguage?letter_title=License%20Request&show_season_id=${parseInt(this.state.showSeasonId)}`, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
        .then(response => {
          let formattedList = response.data?.map(item => ({ value: item.show_season_letter_language_id ? item.show_season_letter_language_id : item.letter_language_id, text: item.text_type, show_season_id: item.show_season_letter_language_id ? 1 : 0 }));
          this.setState({ useConfirmationOptionList: formattedList });
        },
          (err) => {
            console.log("Error in fetching Email Types:", err)
          })
    }

    getData2 = () => {
      clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/userDefinedLanguage?letter_title=License%20Request&show_season_id=${parseInt(this.state.showSeasonId)}`, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
        .then(response => {
          let formattedList = response.data?.map(item => ({ value: item.show_season_letter_language_id ? item.show_season_letter_language_id : item.letter_language_id, text: item.text_type, show_season_id: item.show_season_letter_language_id ? 1 : 0 }));
          this.setState({ licRequestOptionList: formattedList });
        },
          (err) => {
            console.log("Error in fetching Email Types:", err)
          })
    }

    getData3 = () => {
      clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/userDefinedLanguage?letter_title=Check%20Transmittal%20(Initial Fee)&show_season_id=${parseInt(this.state.showSeasonId)}`, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
        .then(response => {
          let formattedList = response.data?.map(item => ({ value: item.show_season_letter_language_id ? item.show_season_letter_language_id : item.letter_language_id, text: item.text_type, show_season_id: item.show_season_letter_language_id ? 1 : 0 }));
          this.setState({ checkTransmittalOptionList: formattedList});
        },
          (err) => {
            console.log("Error in fetching Email Types:", err)
          })
    }

    getData4 = () => {
      let data = {
        letter_title: "Notice of Use", show_season_id: parseInt(this.state.showSeasonId),
      }
      clearTrackService.getData(Constants.ClearTrackServiceBaseUrl + `/userDefinedLanguage?letter_title=Notice%20of%20Use&show_season_id=${parseInt(this.state.showSeasonId)}`, this.props.userContext?.active_tenant?.tenant_id, this.props?.division_id)
        .then(response => {
          let formattedList = response.data?.map(item => ({ value: item.show_season_letter_language_id ? item.show_season_letter_language_id : item.letter_language_id, text: item.text_type, show_season_id: item.show_season_letter_language_id ? 1 : 0 }));
          this.setState({ noticeOfUseOptionList: formattedList });
        },
          (err) => {
            console.log("Error in fetching Email Types:", err)
          })
    }

    handleTabChange = (newTabValue) => {
      let tabLabel = this.state.lettersTabList.filter((item) =>
        item.value == newTabValue ? item : null
      );
      this.setState({
        tabValue: newTabValue,
        tabLabel: tabLabel[0]?.label,
      });
    };

    onChangeCallBack = (field, val, eventValue, data) => {
      let newValue = val.filter(
        (value, index, arr) =>
          index === arr.findIndex((item) => item.value === value.value)
      );
      let licensorValue = newValue?.map((item) => ({
        value: item.value,
        text: item.text,
        ...(item?.licensor_type? {licensor_type: item?.licensor_type}: {}),
        ...(item?.show_season_id || item?.show_season_id === 0 ? {show_season_id: item?.show_season_id}: {}),
        ...(item?.clearance_licensor_id? {clearance_licensor_id: item?.clearance_licensor_id}: {}),
      }));
      if (field === "quoteRequest_list") {
        this.handleChange("quoteRequest_list", licensorValue);
      } else if (field === "confirmationLicensor_list") {
        this.handleChange("confirmationLicensor_list", licensorValue);
      }else if (field === "useConfirmationLicensor_list") {
        this.handleChange("useConfirmationLicensor_list", licensorValue);
      } else if (field === "licenseRequest_list") {
        this.handleChange("licenseRequest_list", licensorValue);
      } else if (field === "LicenseRequestValue") {
        this.handleChange("LicenseRequestValue", licensorValue, data);
      } else if (field === "checkTransmittalLicensor_list") {
        this.handleChange("checkTransmittalLicensor_list", licensorValue);
      } else if (field === "checkTransmittalAPLicensor_list") {
        this.handleChange("checkTransmittalAPLicensor_list", licensorValue);
      } else if (field === "noticeOfUse_list") {
        this.handleChange("noticeOfUse_list", licensorValue);
      } else if (field === "checkRequestValue") {
        this.handleChange("checkRequestValue", licensorValue, data)
      } else if (field === "checkRequestAPValue") {
        this.handleChange("checkRequestAPValue", licensorValue, data);
      } else if (field === "checkTransmittalValue") {
        this.handleChange("checkTransmittalValue", licensorValue, data)
      } else if (field === "checkTransmittalAPValue") {
        this.handleChange("checkTransmittalAPValue", licensorValue, data);
      } else if (field === "producerMemo") {
        this.handleChange("producerMemo", licensorValue)
      } else {
        this.handleChange("licensor_list", licensorValue);

      }
    };

    feeCheckMethod = () => {
          //return this.props?.mainTitle === 'clearence' ?  this.props?.userContext?.active_tenant?.division_role?.find(item => item?.division_id === this.props?.division_id)?.role_name === "Music Supervisor" ? 
          return this.props?.licensorListData[0]?.rights[0]?.fee > 0 
    } 


    handleChange = (field, value, data) => {
      this.setState((prevState) => ({
        licensorLetterData: {
          ...prevState.licensorLetterData,
          [field]: value,
          ['noticeOfUseRevisedNotes'] : field === 'noticeOfUseRevised' ? value === 1 ? "PLEASE NOTE THAT THIS IS A REVISED VERSION OF THE QUOTE REQUEST" : null :
                                        field === 'noticeOfUseRevisedNotes' ? value : prevState?.licensorLetterData?.noticeOfUseRevisedNotes || null,
          ['urgentNotes'] : field === 'urgent' ? value === 1 && this.feeCheckMethod() ? "PLEASE ADVISE IF YOU WILL AGREE TO THE FEES INDICATED BELOW" : null :
                            field === 'urgentNotes' ? value : prevState?.licensorLetterData?.urgentNotes || null,
          ['revisedNotes'] : field === 'revised' ? value === 1 ? "PLEASE NOTE THAT THIS IS A REVISED VERSION OF THE QUOTE REQUEST" : null : 
                            field === 'revisedNotes' ? value : prevState?.licensorLetterData?.revisedNotes || null,
          ['licRequestRevisedNotes'] : field === 'licRequestRevised' ? value === 1 ? "PLEASE NOTE THAT THIS IS A REVISED VERSION OF THE LICENSE REQUEST" : null :
                                      field === 'licRequestRevisedNotes' ? value : prevState?.licensorLetterData?.licRequestRevisedNotes || null,
        },
      }), () => {
        if (field === "checkRequestValue" || field === "checkRequestAPValue" || field === "checkTransmittalValue" || field === "checkTransmittalAPValue" || field === "LicenseRequestValue") {
          this.setState({ values: data })
        }
        if (field === "licensor_list") {
          this.setState((prevState) => ({
            licensorLetterData: {
              ...prevState.licensorLetterData,
              ["checkRequestValue"]: [],
              ["checkRequestAPValue"]: [],
              ["checkTransmittalValue"]: [],
              ["checkTransmittalAPValue"]: [],
              ["LicenseRequestValue"]:[],
            },
          }))
          let status = this.state.status;
          status = this.props?.licensorListData.find(item => item.licensor_id === this.state.licensorLetterData?.licensor_list[0]?.value)?.status
          this.setState({ status: status }, () => {
            if (this.state.status !== "Using") {
              this.setState((prevState) => ({
                licensorLetterData: {
                  ...prevState.licensorLetterData,
                  checkRequest: 0,
                  checkRequestAP: 0,
                  checkTransmittal: 0,
                  checkTransmittalAP: 0
                }
              }))
              let letterTabList = [...this.state.lettersTabList];
              let filteredTabList = letterTabList.filter(item => item.label !== "Check Request" && item.label !== "Check Request A P" && item.label !== "Check Transmittal" && item.label !== "Check Transmittal A P")
              this.setState({ lettersTabList: filteredTabList }, () => {
                if (this.state.lettersTabList.length === 0) {
                  this.setState({ tabValue: null, tabLabel: "" })
                } else {
                  this.setState({ tabValue: this.state.lettersTabList[0].value, tabLabel: this.state.lettersTabList[0].label })
                }
              })
            }
          })
        }
      })
    };

    getDataToOpenExisting = (lettersArray) => {
      let response = [...this.state.response];
      let modifiedResponse = response.map(item => {
        let newObj = { ...item }
        newObj.report_name = this.getReportName(this.state.isMainTitle, null, item.report_name, true)
        return newObj;
      }
      )
      let fileDoesntExist = modifiedResponse?.filter(item => item.fileExists === 0)
      let fileExists = modifiedResponse?.filter(item => item.fileExists === 1 && lettersArray.find(ele => ele.letter === item.report_name)?.value === "openExisting")
      let fileExistsButRecreate = modifiedResponse?.filter(item => item.fileExists === 1 && lettersArray.find(ele => ele.letter === item.report_name)?.value === "Recreate")
      let licensorList = [...this.state.licensorLetterData?.licensor_list]
      let lettersTabList = [...this.state.lettersTabList]
      for (let i = 0; i < licensorList?.length; i++) {
        for (let j = 0; j < lettersTabList?.length; j++) {
          if (fileExists?.length > 0) {
            let checkLetterUrl = fileExists?.find(item => item.licensor_id === licensorList[i].value && item.report_name === lettersTabList[j].label)
            if (checkLetterUrl) {
              this.downloadUrl(checkLetterUrl?.url, checkLetterUrl?.filename, lettersTabList[j].label, licensorList[i].text)
            }
          }
          if (fileDoesntExist?.length > 0) {
            let checkLetterDoesntExist = fileDoesntExist?.find(item => item.licensor_id === licensorList[i].value && item.report_name === lettersTabList[j].label)
            if (checkLetterDoesntExist) {
              this.handleLetterCreation([licensorList[i]], [lettersTabList[j]], true)
            }
          }
          if (fileExistsButRecreate?.length > 0) {
            let checkLetterExistButRecreate = fileExistsButRecreate?.find(item => item.licensor_id === licensorList[i].value && item.report_name === lettersTabList[j].label)
            if (checkLetterExistButRecreate) {
              this.handleLetterCreation([licensorList[i]], [lettersTabList[j]], true)
            }
          }
        }
      }
    }

    handleHideExcelAndDocModal = () => {
      this.setState({ showExcelAndDocModal: false });
    }


    handleChangeCheckRequest = (field, value, data) => {
      this.setState((prevState) => ({
        licensorLetterData: {
          ...prevState.licensorLetterData,
          [field]: value,
        },
        values: data
      }));
    };
    handleCheckboxChange = (field, value) => {
      if((field === 'license' || field ==='licenseCoverLetter' || field === 'checkRequest' || field ==='checkRequestAP' || field === 'checkTransmittal' || field ==='checkTransmittalAP') && this.state.clearanceCOAOption) {
        this.setState((prevState) => ({
          licensorLetterData: {
            ...prevState.licensorLetterData,
            [field+'coa_type']: value ? 'ccmal':null,
          },
        }));
      }
      if (((field === "checkRequest" && value === 1) || (field === "checkRequestAP" && value === 1) || (field === "checkTransmittalAP" && value === 1) || (field === "checkTransmittal" && value === 1)) && this.state.status !== "Using") {
        this.setState({ statusNotInUsingAlert: true })
      } else {
        this.setState((prevState) => ({
          licensorLetterData: {
            ...prevState.licensorLetterData,
            [field]: value,
          },
        }), () => {
          let modifyFieldName = field.replace(/([A-Z])/g, " $1");
        let modifiedName = modifyFieldName.charAt(0).toUpperCase() + modifyFieldName.slice(1);
        if (field && value === 1) {
          this.setState({ tabValue: this.state.tabValue + 1, tabLabel: modifiedName });
          let letterTabList = [...this.state.lettersTabList];
          let newTab = {
            label: modifiedName,
            value: letterTabList.length + 1,
          };
          letterTabList.push(newTab);
          this.setState({ lettersTabList: letterTabList, checkRequestReportSelect: field === "checkRequest" || field === "checkRequestAP" || field === "checkTransmittalAP" || field === "checkTransmittal" || field === "producerMemo" }, () => { this.handleTabChange(newTab.value); });
        } else {
          let reducedTabList = this.state.lettersTabList.filter(
            (item) => item.label !== modifiedName
          );
          let reducedTabValue = [...reducedTabList]
          if (reducedTabValue.length !== 0) {
            reducedTabValue[0].value = 1;
          } else {
            this.setState({ tabValue: null, tabLabel: "" });
          }
          this.setState({
            lettersTabList: reducedTabValue,
            checkRequestReportSelect: false
          }, () => { this.handleTabChange(1); });
        }
      });
      
      }
    };
    selectedTabComponent = () => {
      switch (this.state.tabLabel) {
        case "Quote Request":
          return this.quoteRequest();
        case "Confirmation":
          return this.confirmation();
          case "Use Confirmation":
            return this.useConfirmation();  
        case "License Request":
          return this.licenseRequest();
        case "License":
          return this.newLicense();
        case "License Cover Letter":
          return this.licenseCoverLetter();
        case "Check Request":
          return this.checkRequestOrAP("Check Request");
        case "Check Request A P":
          return this.checkRequestOrAP("Check Request AP");
        case "Check Transmittal":
          return this.checkTransmittalOrAP("Check Transmittal");
        case "Check Transmittal A P":
          return this.checkTransmittalOrAP("Check Transmittal AP");
        case "Producer Memo":
          return this.producerMemo();
        case "Notice Of Use":
          return this.noticeOfUse();
      }
    };


    calculateLetterCount = () => {
      letterCount = 0;
      this.setState({ count: 0 })
      this.state.licensorLetterData?.licensor_list.map(item => {
        this.state.lettersTabList?.map(items => {
          switch (items.label) {
            case "Quote Request":
              letterCount = letterCount + 1;
              break;
            case "Confirmation":
              letterCount = letterCount + 1;
              break;
              case "Use Confirmation":
                letterCount = letterCount + 1;
                break;
            case "License":
              letterCount = letterCount + 1;
              break;
            case "License Cover Letter":
              letterCount = letterCount + 1;
              break;
            case "License Request":
              letterCount = letterCount + 1;
              break;
            case "Check Request":
              if (this.state.licensorLetterData.licensor_list?.length === 1) {
                letterCount = letterCount + 1;
              }
              break;
            case "Check Request A P":
              if (this.state.licensorLetterData.licensor_list?.length === 1) {
                letterCount = letterCount + 1;
              }
              break;
            case "Check Transmittal":
              if (this.state.licensorLetterData.licensor_list?.length === 1) {
                letterCount = letterCount + 1;
              }
              break;
            case "Check Transmittal A P":
              if (this.state.licensorLetterData.licensor_list?.length === 1) {
                letterCount = letterCount + 1;
              }
              break;
            case "Producer Memo":
              letterCount = letterCount + 1;
              break;
            case "Notice Of Use":
              letterCount = letterCount + 1;
              break;
          }
        })
      })
      return letterCount;
    }


    handleLetterCreation = (licensorLetterData, lettersTabList, generate_flag = false, countCheck = false) => {
      if(licensorLetterData?.length ===0 ){
        return
      }
      let finalArray = this.state.finalArray;
      if (countCheck) {
        this.calculateLetterCount()
        finalArray = []
        this.setState({ finalArray })
      }
      this.setState({ postInitiated: true, isPosting: true });
      let isCOA = this.props?.licensorsInfo[0]?.songs_list?.some(item => item?.is_coa === 1)
      licensorLetterData.map(item => {
        lettersTabList?.map(items => {
          let payload = {}
          let { licensorLetterData, showSeasonId , showId , seasonId, songId, showSeasonEpisodeId, licensorInfor } = this.state

          let quoteRequest_userDefined_showSeason = licensorLetterData.quoteRequest_list?.filter(item => item.show_season_id === 1);
          let quoteRequest_userDefined = licensorLetterData.quoteRequest_list?.filter(item => item.show_season_id === 0);
          quoteRequest_userDefined_showSeason = quoteRequest_userDefined_showSeason?.map(item => item.value)
          quoteRequest_userDefined = quoteRequest_userDefined?.map(item => item.value)

          let confirmation_userDefined_showSeason = licensorLetterData.confirmationLicensor_list?.filter(item => item.show_season_id === 1);
          let confirmation_userDefined = licensorLetterData.confirmationLicensor_list?.filter(item => item.show_season_id === 0);
          confirmation_userDefined_showSeason = confirmation_userDefined_showSeason?.map(item => item.value)
          confirmation_userDefined = confirmation_userDefined?.map(item => item.value)

          let useConfirmation_userDefined_showSeason = licensorLetterData.useConfirmationLicensor_list?.filter(item => item.show_season_id === 1);
          let useConfirmation_userDefined = licensorLetterData.useConfirmationLicensor_list?.filter(item => item.show_season_id === 0);
          useConfirmation_userDefined_showSeason = useConfirmation_userDefined_showSeason?.map(item => item.value)
          useConfirmation_userDefined = useConfirmation_userDefined?.map(item => item.value)

          let licenseRequest_userDefined_showSeason = licensorLetterData.licenseRequest_list?.filter(item => item.show_season_id === 1);
          let licenseRequest_userDefined = licensorLetterData.licenseRequest_list?.filter(item => item.show_season_id === 0);
          licenseRequest_userDefined_showSeason = licenseRequest_userDefined_showSeason?.map(item => item.value)
          licenseRequest_userDefined = confirmation_userDefined?.map(item => item.value)

          let noticeOfUse_userDefined_showSeason = licensorLetterData.noticeOfUse_list?.filter(item => item.show_season_id === 1);
          let noticeOfUse_userDefined = licensorLetterData.noticeOfUse_list?.filter(item => item.show_season_id === 0);
          noticeOfUse_userDefined_showSeason = noticeOfUse_userDefined_showSeason?.map(item => item.value)
          noticeOfUse_userDefined = noticeOfUse_userDefined?.map(item => item.value)

          let checkTransmittal_userDefined_showSeason = licensorLetterData.checkTransmittalLicensor_list?.filter(item => item.show_season_id === 1);
          let checkTransmittal_userDefined = licensorLetterData.checkTransmittalLicensor_list?.filter(item => item.show_season_id === 0);
          checkTransmittal_userDefined_showSeason = checkTransmittal_userDefined_showSeason?.map(item => item.value)
          checkTransmittal_userDefined = checkTransmittal_userDefined?.map(item => item.value)


          let checkTransmittalAP_userDefined_showSeason = licensorLetterData.checkTransmittalAPLicensor_list?.filter(item => item.show_season_id === 1);
          let checkTransmittalAP_userDefined = licensorLetterData.checkTransmittalAPLicensor_list?.filter(item => item.show_season_id === 0);
          checkTransmittalAP_userDefined_showSeason = checkTransmittalAP_userDefined_showSeason?.map(item => item.value)
          checkTransmittalAP_userDefined = checkTransmittalAP_userDefined?.map(item => item.value)

          // let licensorIds = licensorLetterData.licensor_list.map(item => item.value)
          let licensor_type =  item?.licensor_type || null
          let clearance_licensor_id = this.state.isMainTitle === "mainTitle" ? null : item?.clearance_licensor_id || null// this.props?.licensorListData.find(items => items.licensor_id === item.value).clearance_licensor_id
          let main_title_licensor_id =   this.state.isMainTitle === "mainTitle" ? item?.clearance_licensor_id || null  : null //this.props?.licensorListData.find(items => items.licensor_id === item.value).main_title_licensor_id
          let clearance_id = licensorInfor[0].clearance_id
          let main_title_id = licensorInfor[0].main_title_id
          let objToBePushed = {}
          switch (items.label) {
            case "Quote Request":
              payload = {
                "is_urgent": licensorLetterData.urgent,
                "urgent_text": licensorLetterData?.urgentNotes,
                "is_revised": licensorLetterData.revised,
                "revised_text": licensorLetterData?.revisedNotes,
                "include_exec_producer_card": licensorLetterData.execProducerCard,
                "include_add_card_language": licensorLetterData.includeAdCard,
                "include_alt_6yr_add_card": licensorLetterData?.altAdCard,
                "include_pilot_quote_extension": licensorLetterData.includePilotQuote,
                "include_pre_air_marketing": licensorLetterData.includePreAirMarketing,
                "include_mid_season_language": licensorLetterData.includeQuoteMidSeason,
                "include_proposed_fees": licensorLetterData?.includeProposedFees,
                "user_defined_language": licensorLetterData?.userDefinedLanguage,
                "user_defined_language_ids": quoteRequest_userDefined?.length > 0 ? quoteRequest_userDefined?.join() : null,
                "show_season_letter_language_id": quoteRequest_userDefined_showSeason?.length > 0 ? quoteRequest_userDefined_showSeason?.join() : null,
                "send_email": this.props?.EditEmailLetter? licensorLetterData.email_letters ? 1 : 0 : 0,
                "attach_w8ben": 1,
                "attach_w8ben_e": 1,
                "attach_w9": 1,
                "show_season_id": parseInt(showSeasonId),
                "season_id": seasonId,
                "show_id": showId,
                "licensor_id": item.value,
                "licensor_name": item.text,
                "show_season_episode_id": parseInt(showSeasonEpisodeId),
                "is_draft": this.props?.EditEmailLetter? licensorLetterData.email_letters ? 1 : 0 : 0,
                "has_attachment": this.props?.EditEmailLetter? licensorLetterData.email_letters ? 1 : 0 : 0,
                "song_id": songId,
                "clearance_licensor_id": clearance_licensor_id,
                "main_title_licensor_id": main_title_licensor_id,
                "letter_type": "QUOTE REQUEST",
                "isOpenExisting": 0
                // "isOpenExisting": lettersArray.find(letter => letter?.letter === items?.label)?.value === "Recreate" ? 0 : 1
              }
              if (!generate_flag) {
                objToBePushed = {
                  report_name: this.state.isMainTitle === "mainTitle" ? "rptQRMainTitle" : "rptQRClearance",
                  body: payload
                }
                finalArray.push(objToBePushed);
              } else {
                return this.handleFinalLetterCreation("Quote Request", "rptQRMainTitle", "rptQRClearance", payload, item.text);
              }
              break;
            case "Confirmation":
              this.state.isMainTitle === "mainTitle" ?
                payload = {
                  "main_title_id": main_title_id,
                  "main_title_licensor_id": main_title_licensor_id,
                  "revised_flag": licensorLetterData?.confirmationRevised,
                  "revised_text": licensorLetterData?.confirmRevisedNotes,
                  "show_season_episode_id": parseInt(showSeasonEpisodeId),
                  "is_draft": this.props?.EditEmailLetter? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "has_attachment":this.props?.EditEmailLetter? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "licensor_type": licensor_type,
                  "licensor_id": item.value,
                  "licensor_name": item.text,
                  "song_id": songId,
                  "letter_type": "CONFIRM",
                  "send_email": this.props?.EditEmailLetter? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "show_season_id": parseInt(showSeasonId),
                  "season_id": seasonId,
                  "show_id": showId,
                  "isOpenExisting": 0,
                  "user_defined_language_flag": licensorLetterData?.confirmUserDefined,
                  "user_defined_language_ids": confirmation_userDefined?.length > 0 ? confirmation_userDefined?.join() : null,
                  "show_season_letter_language_id": confirmation_userDefined_showSeason?.length > 0 ? confirmation_userDefined_showSeason?.join() : null
                }
                :
                payload = {
                  "clearance_record_id": clearance_id,
                  "clearance_licensor_id": clearance_licensor_id,
                  "licensor_type": licensor_type,
                  "revised_flag": licensorLetterData?.confirmationRevised,
                  "revised_text": licensorLetterData?.confirmRevisedNotes,
                  "user_defined_language_flag": licensorLetterData?.confirmUserDefined,
                  "user_defined_language_ids": confirmation_userDefined?.length > 0 ? confirmation_userDefined?.join() : null,
                  "show_season_letter_language_id": confirmation_userDefined_showSeason?.length > 0 ? confirmation_userDefined_showSeason?.join() : null,
                  "show_season_id": parseInt(showSeasonId),
                  "season_id": seasonId,
                  "show_id": showId,
                  "licensor_id": item.value,
                  "licensor_name": item.text,
                  "show_season_episode_id": parseInt(showSeasonEpisodeId),
                  "is_draft": this.props?.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "has_attachment": this.props?.EditEmailLetter? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "song_id": songId,
                  "letter_type": "CONFIRM",
                  "send_email": this.props?.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "isOpenExisting": 0
                  // "isOpenExisting": lettersArray.find(letter => letter?.letter === items?.label)?.value === "Recreate" ? 0 : 1
                }
              if (!generate_flag) {
                objToBePushed = {
                  report_name: this.state.isMainTitle === "mainTitle" ? "rptMainTitleConfirmationLetter" : "rptClearanceConfirmationLetter",
                  body: payload
                }
                finalArray.push(objToBePushed);
              } else {
                return this.handleFinalLetterCreation("Confirmation", "rptMainTitleConfirmationLetter", "rptClearanceConfirmationLetter", payload, item.text);
              }
              break;

              case "Use Confirmation":
              this.state.isMainTitle === "mainTitle" ?
                payload = {
                  "main_title_id": main_title_id,
                  "main_title_licensor_id": main_title_licensor_id,
                  "revised_flag": licensorLetterData?.useConfirmationRevised,
                  "revised_text": licensorLetterData?.useConfirmRevisedNotes,
                  "show_season_episode_id": parseInt(showSeasonEpisodeId),
                  "is_draft": this.props?.EditEmailLetter? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "has_attachment":this.props?.EditEmailLetter? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "licensor_type": licensor_type,
                  "licensor_id": item.value,
                  "licensor_name": item.text,
                  "song_id": songId,
                  "letter_type": "USE CONFIRM",
                  "send_email": this.props?.EditEmailLetter? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "show_season_id": parseInt(showSeasonId),
                  "season_id": seasonId,
                  "show_id": showId,
                  "isOpenExisting": 0,
                  "user_defined_language_flag": licensorLetterData?.useConfirmUserDefined,
                  "user_defined_language_ids": useConfirmation_userDefined?.length > 0 ? useConfirmation_userDefined?.join() : null,
                  "show_season_letter_language_id": useConfirmation_userDefined_showSeason?.length > 0 ? useConfirmation_userDefined_showSeason?.join() : null
                }
                :
                payload = {
                  "clearance_record_id": clearance_id,
                  "clearance_licensor_id": clearance_licensor_id,
                  "licensor_type": licensor_type,
                  "revised_flag": licensorLetterData?.useConfirmationRevised,
                  "revised_text": licensorLetterData?.useConfirmRevisedNotes,
                  "user_defined_language_flag": licensorLetterData?.useConfirmUserDefined,
                  "user_defined_language_ids": useConfirmation_userDefined?.length > 0 ? useConfirmation_userDefined?.join() : null,
                  "show_season_letter_language_id": useConfirmation_userDefined_showSeason?.length > 0 ? useConfirmation_userDefined_showSeason?.join() : null,
                  "show_season_id": parseInt(showSeasonId),
                  "season_id": seasonId,
                  "show_id": showId,
                  "licensor_id": item.value,
                  "licensor_name": item.text,
                  "show_season_episode_id": parseInt(showSeasonEpisodeId),
                  "is_draft": this.props?.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "has_attachment": this.props?.EditEmailLetter? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "song_id": songId,
                  "letter_type": "USE CONFIRM",
                  "send_email": this.props?.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "isOpenExisting": 0
                }
              if (!generate_flag) {
                objToBePushed = {
                  report_name: this.state.isMainTitle === "mainTitle" ? "rptMainTitleUseConfirmationLetter" : "rptClearanceUseConfirmationLetter",
                  body: payload
                }
                finalArray.push(objToBePushed);
              } else {
                return this.handleFinalLetterCreation("UseConfirmation", "rptMainTitleUseConfirmationLetter", "rptClearanceUseConfirmationLetter", payload, item.text);
              }
              break;
            
              
            case "License":
              let report_COA_name = isCOA ? "rptClearanceCOALicense" : "rptClearanceLicenseLetter";
              let license_userDefined = licensorLetterData.licenseRequest_list?.map(item => item.value)

              this.state.isMainTitle === "mainTitle" ?
                payload = {
                  "main_title_id": main_title_id,
                  "revised_flag": licensorLetterData.licRequestRevised,
                  "revised_text": licensorLetterData.licRequestRevisedNotes,
                  "user_defined_language_flag": licensorLetterData.licRequestUserDefined,
                  "user_defined_language_ids": license_userDefined?.length > 0 ? license_userDefined?.join() : null,
                  "show_season_letter_language_id": licenseRequest_userDefined_showSeason?.length > 0 ? licenseRequest_userDefined_showSeason?.join() : null,
                  "they_pay": licensorLetterData.licenseRequestCheck === "TheyPay" ? 1 : 0,
                  "na": licensorLetterData.licenseRequestCheck === "N/A" ? 1 : 0,
                  "show_season_id": parseInt(showSeasonId),
                  "season_id": seasonId,
                  "show_id": showId,
                  "licensor_type": licensor_type,
                  "licensor_id": item.value,
                  "licensor_name": item.text,
                  "show_season_episode_id": parseInt(showSeasonEpisodeId),
                  "is_draft": this.props?.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "has_attachment": this.props?.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "song_id": songId,
                  "isOpenExisting": 0,
                  "clearance_licensor_id": clearance_licensor_id,
                  "letter_type": "LICENSE",
                  "send_email": this.props?.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "main_title_licensor_id": main_title_licensor_id
                }
                :
              payload = {
                "clearance_record_id": clearance_id,
                "clearance_licensor_id": clearance_licensor_id,
                "licensor_type": licensor_type,
                "they_pay": licensorLetterData.licenseCheck === "TheyPay" ? 1 : 0,
                "na": isCOA ? null : licensorLetterData.licenseCheck === "N/A" ? 1 : 0,
                "show_season_id": parseInt(showSeasonId),
                'coa_type' : isCOA ? licensorLetterData?.licensecoa_type : null,
                "season_id": seasonId,
                "show_id": showId,
                "licensor_id": item.value,
                "licensor_name": item.text,
                "show_season_episode_id": parseInt(showSeasonEpisodeId),
                "is_draft": this.props?.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 : 0,
                "has_attachment": this.props?.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 : 0,
                "song_id": songId,
                "clearance_licensor_id": clearance_licensor_id,
                "letter_type": "LICENSE",
                "send_email": this.props?.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 : 0,
                "isOpenExisting": 0,

                // "isOpenExisting": lettersArray.find(letter => letter?.letter === items?.label)?.value === "Recreate" ? 0 : 1
              }
              if (!generate_flag) {
                objToBePushed = {
                  report_name: this.state.isMainTitle === "mainTitle" ? "rptMainTitleLicenseLetter" : report_COA_name,
                  body: payload
                }
                finalArray.push(objToBePushed);
              } else {
                return this.handleFinalLetterCreation("License", "rptMainTitleLicenseLetter", report_COA_name, payload, item.text);
              }
              break;
            case "License Cover Letter":
              let report_COA_name_cover = isCOA ? "rptClearanceCOALicenseCoverLetter" : "rptClearanceLicenseCoverLetter"
              let licenseCoverLetter_userDefined = licensorLetterData.licenseRequest_list?.map(item => item.value)
              this.state.isMainTitle === "mainTitle" ?
                payload = {
                  "main_title_id": main_title_id,
                  "revised_flag": licensorLetterData.licRequestRevised,
                  "revised_text": licensorLetterData.licRequestRevisedNotes,
                  "user_defined_language_flag": licensorLetterData.licRequestUserDefined,
                  "user_defined_language_ids": licenseCoverLetter_userDefined?.length > 0 ? licenseCoverLetter_userDefined?.join() : null,
                  "show_season_letter_language_id": licenseRequest_userDefined_showSeason?.length > 0 ? licenseRequest_userDefined_showSeason?.join() : null,
                  "they_pay": licensorLetterData.licenseRequestCheck === "TheyPay" ? 1 : 0,
                  "na": licensorLetterData.licenseRequestCheck === "N/A" ? 1 : 0,
                  "show_season_id": parseInt(showSeasonId),
                  "season_id": seasonId,
                  "show_id": showId,
                  "licensor_type": licensor_type,
                  "licensor_id": item.value,
                  "licensor_name": item.text,
                  "show_season_episode_id": parseInt(showSeasonEpisodeId),
                  "is_draft": this.props?.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "has_attachment": this.props?.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "song_id": songId,
                  "isOpenExisting": 0,
                  "clearance_licensor_id": clearance_licensor_id,
                  "letter_type": "LICENSE_COVER_LETTER",
                  "send_email": this.props?.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "main_title_licensor_id": main_title_licensor_id
                }
                :
              payload = {
                "clearance_record_id": clearance_id,
                "clearance_licensor_id": clearance_licensor_id,
                "licensor_type": licensor_type,
                "signer_id": isCOA ? null :licensorLetterData.coverLetterSignature,
                'coa_type' : isCOA ? licensorLetterData?.licenseCoverLettercoa_type : null,
                "status_text": isCOA ? null : licensorLetterData.licenseCoverLetterLetterType,
                "show_season_id": parseInt(showSeasonId),
                "season_id": seasonId,
                "show_id": showId,
                "licensor_id": item.value,
                "licensor_name": item.text,
                "show_season_episode_id": parseInt(showSeasonEpisodeId),
                "is_draft": this.props?.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 : 0,
                "has_attachment": this.props.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 : 0,
                "song_id": songId,
                "clearance_licensor_id": clearance_licensor_id,
                "letter_type": "LICENSE_COVER_LETTER",
                "send_email": this.props?.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 :0,
                "isOpenExisting": 0
                // "isOpenExisting": lettersArray.find(letter => letter?.letter === items?.label)?.value === "Recreate" ? 0 : 1
              }
              if (!generate_flag) {
                objToBePushed = {
                  report_name: this.state.isMainTitle === "mainTitle" ? "rptMainTitleLicenseCoverLetter" : report_COA_name_cover,
                  body: payload
                }
                finalArray.push(objToBePushed);
              } else {
                return this.handleFinalLetterCreation("License Cover Letter", "rptMainTitleLicenseCoverLetter", report_COA_name_cover, payload, item.text);
              }
              break;
            case "License Request":
              let licenseRequest_userDefined = licensorLetterData.licenseRequest_list?.map(item => item.value)
              if(this.state.isMainTitle === "mainTitle" && !this.state.licensorLetterData?.LicenseRequestValue?.length){
                this.setState({ isPosting: false })
                return
              }
              this.state.isMainTitle === "mainTitle" ?
                payload = {
                  "main_title_id": main_title_id,
                  "revised_flag": licensorLetterData.licRequestRevised,
                  "revised_text": licensorLetterData.licRequestRevisedNotes,
                  "user_defined_language_flag": licensorLetterData.licRequestUserDefined,
                  "user_defined_language_ids": licenseRequest_userDefined?.length > 0 ? licenseRequest_userDefined?.join() : null,
                  "show_season_letter_language_id": licenseRequest_userDefined_showSeason?.length > 0 ? licenseRequest_userDefined_showSeason?.join() : null,
                  "they_pay": licensorLetterData.licenseRequestCheck === "TheyPay" ? 1 : 0,
                  "na": licensorLetterData.licenseRequestCheck === "N/A" ? 1 : 0,
                  "show_season_id": parseInt(showSeasonId),
                  "season_id": seasonId,
                  "show_id": showId,
                  "licensor_type": licensor_type,
                  "licensor_id": item.value,
                  "licensor_name": item.text,
                  "show_season_episode_id": parseInt(showSeasonEpisodeId),
                  "is_draft": this.props?.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "has_attachment": this.props?.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "song_id": songId,
                  "isOpenExisting": 0,
                  "clearance_licensor_id": clearance_licensor_id,
                  "letter_type": "LICENSE_REQUEST",
                  "send_email": this.props?.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "main_title_licensor_id": main_title_licensor_id,
                  "is_gratis": this.props.lic?.notes?.trim()?.toLowerCase()?.includes("gratis")?1:0
                }
                :
                payload = {
                  "clearance_record_id": clearance_id,
                  "clearance_licensor_id": clearance_licensor_id,
                  "licensor_type": licensor_type,
                  "revised_flag": licensorLetterData.licRequestRevised,
                  "revised_text": licensorLetterData.licRequestRevisedNotes,
                  "user_defined_language_flag": licensorLetterData.licRequestUserDefined,
                  "user_defined_language_ids": licenseRequest_userDefined?.length > 0 ? licenseRequest_userDefined?.join() : null,
                  "show_season_letter_language_id": licenseRequest_userDefined_showSeason?.length > 0 ? licenseRequest_userDefined_showSeason?.join() : null,
                  "they_pay": licensorLetterData.licenseRequestCheck === "TheyPay" ? 1 : 0,
                  "na": licensorLetterData.licenseRequestCheck === "N/A" ? 1 : 0,
                  "show_season_id": parseInt(showSeasonId),
                  "season_id": seasonId,
                  "show_id": showId,
                  "licensor_id": item.value,
                  "licensor_name": item.text,
                  "show_season_episode_id": parseInt(showSeasonEpisodeId),
                  "is_draft": this.props?.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "has_attachment": this.props?.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "song_id": songId,
                  "clearance_licensor_id": clearance_licensor_id,
                  "letter_type": "LICENSE_REQUEST",
                  "send_email": this.props?.EditEmailLetter ? licensorLetterData.email_letters ? 1 : 0 : 0,
                  "isOpenExisting": 0,
                  "is_gratis": this.props.lic?.notes?.trim()?.toLowerCase()?.includes("gratis")?1:0
                  // "isOpenExisting": lettersArray.find(letter => letter?.letter === items?.label)?.value === "Recreate" ? 0 : 1
                }
                if(this.state.isMainTitle === "mainTitle" && this.state.licensorLetterData?.LicenseRequestValue?.length >0){
                  this.state.licensorLetterData.LicenseRequestValue?.map(checkRequestAPItem => {
                    let selectedLRRequest = this.state.values.find((item) => item.value === checkRequestAPItem?.value)
                      payload.main_title_check_request_id = selectedLRRequest?.check_request_id
      
                    if (!generate_flag) {
                      objToBePushed = {
                        report_name: this.state.isMainTitle === "mainTitle" ? "rptLRMainTitle" : "rptLRClearance",
                        body: payload
                      }
                      finalArray.push(objToBePushed);
                    } else {
                      return this.handleFinalLetterCreation("License Request", "rptLRMainTitle", "rptLRClearance", payload, item.text);
                    }
                  })  
                }else{
                  if (!generate_flag) {
                    objToBePushed = {
                      report_name: this.state.isMainTitle === "mainTitle" ? "rptLRMainTitle" : "rptLRClearance",
                      body: payload
                    }
                    finalArray.push(objToBePushed);
                  } else {
                    return this.handleFinalLetterCreation("License Request", "rptLRMainTitle", "rptLRClearance", payload, item.text);
                  }
                }
              break;
            case "Check Request":
              if (licensorLetterData.licensor_list?.length !== 1 ||
                 (this.state.clearanceCOAOption ? this.props?.licensorListData?.find(ele => ele?.licensor_id ===licensorLetterData?.licensor_list[0]?.value)?.clearance_check_request?.length === 0 :
                 (!this.state.licensorLetterData.checkRequestValue || this.state.licensorLetterData.checkRequestValue?.length === 0))) {
                this.setState({ isPosting: false })
                return
              }
              if(this.state.clearanceCOAOption) {
                let choosenLicensor = this.props?.licensorListData?.find(ele=> ele?.clearance_licensor_id === item?.clearance_licensor_id)
                payload = {
                  "clearance_id": this.props?.licensorsInfo[0]?.clearance_id ,
                  "clearance_licensor_ids": choosenLicensor?.clearance_licensor_id,
                  "clearance_check_request_ids":choosenLicensor?.clearance_check_request[0]?.clearance_check_request_id,
                  "licensor_id": item.value,
                  "licensor_name": item.text,
                  'coa_type' : isCOA ? licensorLetterData?.checkRequestcoa_type : null,
                  "generate_letter": 1,
                  "isOpenExisting": 0
                }
              if (!generate_flag) {
                if (licensorLetterData.licensor_list?.length === 1) {
                  objToBePushed = {
                    report_name: this.state.isMainTitle === "mainTitle" ? "rptMainTitleInitialCheckRequestLetter" : "rptClearanceInitialCheckRequestLetter",
                    body: payload
                  }
                  finalArray.push(objToBePushed);
                }
              } else {
                if (licensorLetterData.licensor_list?.length === 1) {
                  return this.handleFinalLetterCreation("CheckRequest", "rptMainTitleInitialCheckRequestLetter", "rptClearanceInitialCheckRequestLetter", payload, item.text);
                }
              }
              } else {               
              this.state.licensorLetterData.checkRequestValue?.map(checkRequestItem => {
                let selectedRequest = this.state.values.find((item) => item.value === checkRequestItem?.value)
                // let id = this.state.isMainTitle === "mainTitle" ? this.state.values.filter(item => item.value === this.state.licensorLetterData?.checkRequestValue)[0].main_title_id : this.state.values.filter(item => item.value === this.state.licensorLetterData?.checkRequestValue)[0].clearance_id
                this.state.isMainTitle === "mainTitle" ?
                  payload = {
                    "main_title_id": selectedRequest?.main_title_id,
                    "main_title_licensor_id": selectedRequest?.main_title_licensor_id,
                    "main_title_check_request_id": selectedRequest?.check_request_id,
                    "generate_letter": 1,
                    "licensor_id": item.value,
                    "licensor_name": item.text,
                    "isOpenExisting": 0,
                    "main_title_song_id": this.props?.song?.main_title_song_id
                  }
                  :
                  payload = {
                    "clearance_id": selectedRequest?.clearance_id,
                    "clearance_licensor_ids": selectedRequest?.clearance_licensor_id,
                    "clearance_check_request_ids": selectedRequest?.check_request_id,
                    "licensor_id": item.value,
                    "licensor_name": item.text,
                    'coa_type' : isCOA ? licensorLetterData?.checkRequestcoa_type : null,
                    "generate_letter": 1,
                    "isOpenExisting": 0
                    // "isOpenExisting": lettersArray.find(letter => letter?.letter === items?.label)?.value === "Recreate" ? 0 : 1
                  }
                if (!generate_flag) {
                  if (licensorLetterData.licensor_list?.length === 1) {
                    objToBePushed = {
                      report_name: this.state.isMainTitle === "mainTitle" ? "rptMainTitleInitialCheckRequestLetter" : "rptClearanceInitialCheckRequestLetter",
                      body: payload
                    }
                    finalArray.push(objToBePushed);
                  }
                } else {
                  if (licensorLetterData.licensor_list?.length === 1) {
                    return this.handleFinalLetterCreation("CheckRequest", "rptMainTitleInitialCheckRequestLetter", "rptClearanceInitialCheckRequestLetter", payload, item.text);
                  }
                }
              })
              }
              break;
            case "Check Request A P":
              if (licensorLetterData.licensor_list?.length !== 1 ||
                (this.state.clearanceCOAOption ? this.props?.licensorListData?.find(ele => ele?.licensor_id ===licensorLetterData?.licensor_list[0]?.value)?.clearance_check_request?.length === 0 : 
                (!this.state.licensorLetterData.checkRequestAPValue || this.state.licensorLetterData.checkRequestAPValue?.length === 0))) {
                this.setState({ isPosting: false })
                return
              }
              if(this.state.clearanceCOAOption) {
                let choosenLicensor = this.props?.licensorListData?.find(ele=> ele?.clearance_licensor_id === item?.clearance_licensor_id)
                payload = {
                  "clearance_id": this.props?.licensorsInfo[0]?.clearance_id,
                  "clearance_licensor_ids": choosenLicensor?.clearance_licensor_id,
                  "clearance_check_request_ids" :choosenLicensor?.clearance_check_request[0]?.clearance_check_request_id,
                  "licensor_id": item.value,
                  "licensor_id": item.value,
                  'coa_type' : isCOA ? licensorLetterData?.checkRequestAPcoa_type : null,
                  "licensor_name": item.text,
                  "generate_letter": 1,
                  "isOpenExisting": 0
                }
                if (!generate_flag) {
                  if (licensorLetterData.licensor_list?.length === 1) {
                    objToBePushed = {
                      report_name: this.state.isMainTitle === "mainTitle" ? "rptMainTitleInitialCheckRequestAPLetter" : "rptClearanceInitialCheckRequestAPLetter",
                      body: payload
                    }
                    finalArray.push(objToBePushed);
                  }
                } else {
                  if (licensorLetterData.licensor_list?.length === 1) {
                    return this.handleFinalLetterCreation("CheckRequestAP", "rptMainTitleInitialCheckRequestAPLetter", "rptClearanceInitialCheckRequestAPLetter", payload, item.text);
                  }
                }
              } else {
                this.state.licensorLetterData.checkRequestAPValue?.map(checkRequestAPItem => {
                  let selectedAPRequest = this.state.values.find((item) => item.value === checkRequestAPItem?.value)
                  this.state.isMainTitle === "mainTitle" ?
                    payload = {
                      "main_title_id": selectedAPRequest?.main_title_id,
                      "main_title_licensor_id": selectedAPRequest?.main_title_licensor_id,
                      "main_title_check_request_id": selectedAPRequest?.check_request_id,
                      "licensor_type": licensor_type,
                      "licensor_id": item.value,
                      "licensor_name": item.text,
                      "generate_letter": 1,
                      "isOpenExisting": 0,
                      "main_title_song_id": this.props?.song?.main_title_song_id
                    }
                    :
                    payload = {
                      "clearance_id": selectedAPRequest?.clearance_id,
                      "clearance_licensor_ids": selectedAPRequest?.clearance_licensor_id,
                      "clearance_check_request_ids": selectedAPRequest?.check_request_id,
                      "licensor_id": item.value,
                      'coa_type' : isCOA ? licensorLetterData?.checkRequestAPcoa_type : null,
                      "licensor_name": item.text,
                      "generate_letter": 1,
                      "isOpenExisting": 0
                      // "isOpenExisting": lettersArray.find(letter => letter?.letter === items?.label)?.value === "Recreate" ? 0 : 1
                    }
                  if (!generate_flag) {
                    if (licensorLetterData.licensor_list?.length === 1) {
                      objToBePushed = {
                        report_name: this.state.isMainTitle === "mainTitle" ? "rptMainTitleInitialCheckRequestAPLetter" : "rptClearanceInitialCheckRequestAPLetter",
                        body: payload
                      }
                      finalArray.push(objToBePushed);
                    }
                  } else {
                    if (licensorLetterData.licensor_list?.length === 1) {
                      return this.handleFinalLetterCreation("CheckRequestAP", "rptMainTitleInitialCheckRequestAPLetter", "rptClearanceInitialCheckRequestAPLetter", payload, item.text);
                    }
                  }
                })
              }
              break;
            case "Check Transmittal":
              if ( licensorLetterData.licensor_list?.length !== 1 ||
                (this.state.clearanceCOAOption ? this.props?.licensorListData?.find(ele => ele?.licensor_id ===licensorLetterData?.licensor_list[0]?.value)?.clearance_check_request?.length === 0  :
                (!this.state.licensorLetterData.checkTransmittalValue || this.state.licensorLetterData.checkTransmittalValue?.length === 0))) {
                this.setState({ isPosting: false })
                return
              }
              if(this.state.clearanceCOAOption) {
                let choosenLicensor = this.props?.licensorListData?.find(ele=> ele?.clearance_licensor_id === item?.clearance_licensor_id)
                payload = {
                  "clearance_id": this.props?.licensorsInfo[0]?.clearance_id,
                  "clearance_licensor_ids": choosenLicensor?.clearance_licensor_id,
                  "clearance_check_request_ids":choosenLicensor?.clearance_check_request[0]?.clearance_check_request_id,
                  "licensor_id": item?.value,
                  "licensor_name": item?.text,
                  "isOpenExisting": 0,
                  'coa_type' : isCOA ? licensorLetterData?.checkTransmittalcoa_type : null,
                  "user_defined_language_flag":  null,
                  "user_defined_language_ids":  null,
                  "show_season_letter_language_id": null,
                }
              if (!generate_flag) {
                if (licensorLetterData.licensor_list?.length === 1) {
                  objToBePushed = {
                    report_name: this.state.isMainTitle === "mainTitle" ? "rptMainTitleCheckRequestTransmittalLetter" : "rptClearanceCheckRequestTransmittalLetter",
                    body: payload
                  }
                  finalArray.push(objToBePushed);
                }
              } else {
                if (licensorLetterData.licensor_list?.length === 1) {
                  return this.handleFinalLetterCreation("CheckTransmittal", "rptMainTitleCheckRequestTransmittalLetter", "rptClearanceCheckRequestTransmittalLetter", payload, item.text);
                }
              }

              } else {
              this.state.licensorLetterData.checkTransmittalValue?.map(checkTransmittalItem => {
                let selectedTransmittalRequest = this.state.values.find((item) => item.value === checkTransmittalItem?.value)
                payload = this.state.isMainTitle === "mainTitle" ?
                  {
                    "main_title_id": selectedTransmittalRequest?.main_title_id,
                    "main_title_licensor_id": selectedTransmittalRequest?.main_title_licensor_id,
                    "main_title_check_request_id": selectedTransmittalRequest?.check_request_id,
                    "licensor_type": licensor_type,
                    "licensor_id": item.value,
                    "licensor_name": item.text,
                    "isOpenExisting": 0,
                    "user_defined_language_flag": licensorLetterData.checkTransmittalUserDefined,
                    "user_defined_language_ids": checkTransmittal_userDefined?.length > 0 ? checkTransmittal_userDefined?.join() : null,
                    "show_season_letter_language_id": checkTransmittal_userDefined_showSeason?.length > 0 ? checkTransmittal_userDefined_showSeason?.join() : null
                  }
                  : {
                    "clearance_id": selectedTransmittalRequest?.clearance_id,
                    "clearance_licensor_id": selectedTransmittalRequest?.clearance_licensor_id,
                    "clearance_check_request_id": selectedTransmittalRequest?.check_request_id,
                    "licensor_id": item?.value,
                    "licensor_name": item?.text,
                    "isOpenExisting": 0,
                    'coa_type' : isCOA ? licensorLetterData?.checkTransmittalcoa_type : null,
                    "user_defined_language_flag": licensorLetterData?.checkTransmittalUserDefined || null,
                    "user_defined_language_ids": checkTransmittal_userDefined?.length > 0 ? checkTransmittal_userDefined?.join() : null,
                    "show_season_letter_language_id": checkTransmittal_userDefined_showSeason?.length > 0 ? checkTransmittal_userDefined_showSeason?.join() : null
                    // "isOpenExisting": lettersArray.find(letter => letter?.letter === items?.label)?.value === "Recreate" ? 0 : 1
                  }
                if (!generate_flag) {
                  if (licensorLetterData.licensor_list?.length === 1) {
                    objToBePushed = {
                      report_name: this.state.isMainTitle === "mainTitle" ? "rptMainTitleCheckRequestTransmittalLetter" : "rptClearanceCheckRequestTransmittalLetter",
                      body: payload
                    }
                    finalArray.push(objToBePushed);
                  }
                } else {
                  if (licensorLetterData.licensor_list?.length === 1) {
                    return this.handleFinalLetterCreation("CheckTransmittal", "rptMainTitleCheckRequestTransmittalLetter", "rptClearanceCheckRequestTransmittalLetter", payload, item.text);
                  }
                }
              })
             }
              break;
            case "Check Transmittal A P":
              if (licensorLetterData.licensor_list?.length !== 1 ||
                (this.state.clearanceCOAOption ? this.props?.licensorListData?.find(ele => ele?.licensor_id === licensorLetterData?.licensor_list[0]?.value)?.clearance_check_request?.length === 0  : 
                  (!this.state.licensorLetterData.checkTransmittalAPValue && this.state.licensorLetterData.checkTransmittalAPValue?.length === 0))) {
                this.setState({ isPosting: false })
                return
              }
              if(this.state.clearanceCOAOption) {
                  let choosenLicensor = this.props?.licensorListData?.find(ele=> ele?.clearance_licensor_id === item?.clearance_licensor_id)
                  payload = {
                    "clearance_id": this.props?.licensorsInfo[0]?.clearance_id,
                    "clearance_licensor_ids": choosenLicensor?.clearance_licensor_id,
                    "clearance_check_request_ids":choosenLicensor?.clearance_check_request[0]?.clearance_check_request_id,
                    "licensor_id": item?.value,
                    "licensor_name": item?.text,
                    "isOpenExisting": 0,
                    "user_defined_language_flag":  null,
                    'coa_type' : isCOA ? licensorLetterData?.checkTransmittalAPcoa_type: null,
                    "user_defined_language_ids": null,
                    "show_season_letter_language_id":  null
                  }
                if (!generate_flag) {
                  if (licensorLetterData.licensor_list?.length === 1) {
                    objToBePushed = {
                      report_name: this.state.isMainTitle === "mainTitle" ? "rptMainTitleCheckRequestTransmittalAPLetter" : "rptClearanceCheckRequestTransmittalAPLetter",
                      body: payload
                    }
                    finalArray.push(objToBePushed);
                  }
                } else {
                  if (licensorLetterData.licensor_list?.length === 1) {
                    return this.handleFinalLetterCreation("CheckTransmittalAP", "rptMainTitleCheckRequestTransmittalAPLetter", "rptClearanceCheckRequestTransmittalAPLetter", payload, item.text);
                  }
                }
              } else {
                this.state.licensorLetterData.checkTransmittalAPValue?.map(checkTransmittalAPItem => {
                  let selectedTransmittalAPRequest = this.state.values.find((item) => item.value === checkTransmittalAPItem?.value)
                  payload = this.state.isMainTitle === "mainTitle" ?
                    {
                      "main_title_id": selectedTransmittalAPRequest?.main_title_id,
                      "main_title_licensor_id": selectedTransmittalAPRequest?.main_title_licensor_id,
                      "main_title_check_request_id": selectedTransmittalAPRequest?.check_request_id,
                      "licensor_type": licensor_type,
                      "licensor_id": item.value,
                      "licensor_name": item.text,
                      "isOpenExisting": 0,
                      "user_defined_language_flag": licensorLetterData.checkTransmittalAPUserDefined,
                      "user_defined_language_ids": checkTransmittalAP_userDefined?.length > 0 ? checkTransmittalAP_userDefined?.join() : null,
                      "show_season_letter_language_id": checkTransmittalAP_userDefined_showSeason?.length > 0 ? checkTransmittalAP_userDefined_showSeason?.join() : null
                    }
                    :
                    {
                      "clearance_id": selectedTransmittalAPRequest?.clearance_id,
                      "clearance_licensor_id": selectedTransmittalAPRequest?.clearance_licensor_id,
                      "clearance_check_request_id": selectedTransmittalAPRequest?.check_request_id,
                      "licensor_id": item?.value,
                      "licensor_name": item?.text,
                      "isOpenExisting": 0,
                      "user_defined_language_flag": licensorLetterData?.checkTransmittalAPUserDefined || null,
                      'coa_type' : isCOA ? licensorLetterData?.checkTransmittalAPcoa_type: null,
                      "user_defined_language_ids": checkTransmittalAP_userDefined?.length > 0 ? checkTransmittalAP_userDefined?.join() : null,
                      "show_season_letter_language_id": checkTransmittalAP_userDefined_showSeason?.length > 0 ? checkTransmittalAP_userDefined_showSeason?.join() : null
                      // "isOpenExisting": lettersArray.find(letter => letter?.letter === items?.label)?.value === "Recreate" ? 0 : 1
                    }
                  if (!generate_flag) {
                    if (licensorLetterData.licensor_list?.length === 1) {
                      objToBePushed = {
                        report_name: this.state.isMainTitle === "mainTitle" ? "rptMainTitleCheckRequestTransmittalAPLetter" : "rptClearanceCheckRequestTransmittalAPLetter",
                        body: payload
                      }
                      finalArray.push(objToBePushed);
                    }
                  } else {
                    if (licensorLetterData.licensor_list?.length === 1) {
                      return this.handleFinalLetterCreation("CheckTransmittalAP", "rptMainTitleCheckRequestTransmittalAPLetter", "rptClearanceCheckRequestTransmittalAPLetter", payload, item.text);
                    }
                  }
                })
              }
              break;
            case "Producer Memo":
              if (!this.state.licensorLetterData.producerMemo && this.state.licensorLetterData.producerMemo.length === 0) {
                this.setState({ isPosting: false })
                return;
              }
              payload =
              {
                "clearance_record_id": clearance_id,
                "clearance_licensor_id": clearance_licensor_id,
                "licensor_type": licensor_type,
                "licensor_id": item.value,
                "licensor_name": item.text,
                "song_id": licensorLetterData.producerMemo[0]?.value,
                "show_season_id": showSeasonId,
                "letter_type": "PRODUCER_MEMO",
                "isOpenExisting": 0,
                "is_draft": this.props?.EditEmailLetter? licensorLetterData.email_letters ? 1 : 0 : 0,
                "has_attachment":this.props?.EditEmailLetter? licensorLetterData.email_letters ? 1 : 0 : 0,
                "send_email": this.props?.EditEmailLetter? licensorLetterData.email_letters ? 1 : 0 : 0
                // "isOpenExisting": lettersArray.find(letter => letter?.letter === items?.label)?.value === "Recreate" ? 0 : 1
              }
              if (!generate_flag) {
                objToBePushed = {
                  report_name: this.state.isMainTitle === "mainTitle" ? "" : "rptClearanceProducerMemo",
                  body: payload
                }
                finalArray.push(objToBePushed);
              } else {
                return this.handleFinalLetterCreation("ProducerMemo", "", "rptClearanceProducerMemo", payload, item.text);
              }
              break;
            case "Notice Of Use":
              payload =
              {
                "main_title_id": main_title_id,
                "main_title_licensor_id": main_title_licensor_id,
                "licensor_type": licensor_type,
                "revised_flag": licensorLetterData.noticeOfUseRevised,
                "revised_text": licensorLetterData.noticeOfUseRevisedNotes,
                "show_season_id": parseInt(showSeasonId),
                "season_id": seasonId,
                "show_id": showId,
                "licensor_id": item.value,
                "licensor_name": item.text,
                "show_season_episode_id": parseInt(showSeasonEpisodeId),
                "is_draft":this.props?.EditEmailLetter? licensorLetterData.email_letters ? 1 : 0:0,
                "has_attachment": this.props?.EditEmailLetter? licensorLetterData.email_letters ? 1 : 0:0,
                "song_id": songId,
                "clearance_licensor_id": clearance_licensor_id,
                "letter_type": "NoticeOfUse",
                "send_email": this.props?.EditEmailLetter? licensorLetterData.email_letters ? 1 : 0:0,
                "main_title_licensor_id": main_title_licensor_id,
                "isOpenExisting": 0
                // "isOpenExisting": letter?.letter === items?.label ? (letter?.value === "Recreate" ? 0 : letter?.value === "OpenExisting" ? 1 : 0) : 0
              }
              if (!generate_flag) {
                objToBePushed = {
                  report_name: this.state.isMainTitle === "mainTitle" ? "rptMainTitleNoticeOfUse" : "",
                  body: payload
                }
                finalArray.push(objToBePushed);
              } else {
                return this.handleFinalLetterCreation("NoticeOfUse", "rptMainTitleNoticeOfUse", "", payload, item.text);
              }
              break;
          }
        })
      })
      if (!generate_flag) {
        this.setState({ finalArray }, () => {
          if (this.state.finalArray?.length > 0) {
            this.getDataForExistingLetters(this.state.finalArray).then(webSocketConn => {
              webSocketConn.onmessage = (e) => {
                let response = JSON.parse(e.data)
                if (response?.statusCode == 200) {
                  this.setState({ isPosting: false, postInitiated: false, finalArray: [] })
                  let bodyResponse = JSON.parse(response?.body)
                  this.generateLetters(bodyResponse)
                  // this.downloadUrl(response?.body?.url, response?.body?.filename);
                  //  this.notificationComponent(true, 'success')   
                } 
                // else {
                //   this.setState({ isPosting: false, postInitiated: false, finalArray: [] })
                //   // this.notificationComponent(true, 'fail')
                // }
              }
            })
          }
        })
      }
    }

    generateLetters = (res) => {
      let response = [...this.state.response]
      let checkIfAnyLetterExists = res?.filter(item => item.fileExists === 1)
      this.setState({ response: res }, () => {
        if (checkIfAnyLetterExists?.length > 0) {
          this.setState({ showExcelAndDocModal: true })
        } else {
          this.handleLetterCreation(this.state.licensorLetterData?.licensor_list, this.state.lettersTabList, true)
        }
      });
    }

    getReportName = (isMainTitle, letterTabName, repName, getTabName = false) => {
      let mainTitleLetters = [{ tabName: "Quote Request", reportName: "rptQRMainTitle" }, { tabName: "Confirmation", reportName: "rptMainTitleConfirmationLetter" }, { tabName: "License Request", reportName: "rptLRMainTitle" }, { tabName: "Check Request", reportName: "rptMainTitleInitialCheckRequestLetter" }, { tabName: "License Cover Letter", reportName: "rptMainTitleLicenseCoverLetter" }, { tabName: "License", reportName: "rptMainTitleLicenseLetter" },{ tabName: "Check Request A P", reportName: "rptMainTitleInitialCheckRequestAPLetter" }, { tabName: "Check Transmittal", reportName: "rptMainTitleCheckRequestTransmittalLetter" }, { tabName: "Check Transmittal A P", reportName: "rptMainTitleCheckRequestTransmittalAPLetter" }, { tabName: "Notice Of Use", reportName: "rptMainTitleNoticeOfUse" }];
      let clearanceLetters = [{ tabName: "Quote Request", reportName: "rptQRClearance" }, { tabName: "Confirmation", reportName: "rptClearanceConfirmationLetter" }, { tabName: "License Request", reportName: "rptLRClearance" }, { tabName: "License Cover Letter", reportName: "rptClearanceLicenseCoverLetter" }, { tabName: "License Cover Letter", reportName: "rptClearanceCOALicenseCoverLetter" }, { tabName: "License", reportName: "rptClearanceLicenseLetter" }, { tabName: "License", reportName: "rptClearanceCOALicense" }, { tabName: "Check Request", reportName: "rptClearanceInitialCheckRequestLetter" }, { tabName: "Check Request A P", reportName: "rptClearanceInitialCheckRequestAPLetter" }, { tabName: "Check Transmittal", reportName: "rptClearanceCheckRequestTransmittalLetter" }, { tabName: "Check Transmittal A P", reportName: "rptClearanceCheckRequestTransmittalAPLetter" }, { tabName: "Producer Memo", reportName: "rptClearanceProducerMemo" }]
      if (getTabName) {
        return isMainTitle === "mainTitle" ? mainTitleLetters?.find(item => item.reportName === repName)?.tabName : clearanceLetters?.find(item => item.reportName === repName)?.tabName
      } else {
        return isMainTitle === "mainTitle" ? mainTitleLetters?.find(item => item.tabName === letterTabName)?.reportName : clearanceLetters?.find(item => item.tabName === letterTabName)?.reportName
      }
    }


    handleFinalLetterCreation = (reportNameFinal, mainTitleReportName, clearenceReportName, payload, licensorName) => {
      // this.setState({ postInitiated: true, isPosting: true });
      let letter_type = payload.letter_type
      let reportName = this.state.isMainTitle === "mainTitle" ? mainTitleReportName : clearenceReportName;
      this.generateReport(reportName, payload).then(webSocketConn => {
        webSocketConn.onmessage = (e) => {
          let response = JSON.parse(e.data)
          if (response?.statusCode == 200) {
            this.setState({ isPosting: false, postInitiated: false })
            this.downloadUrl(response?.body?.url, response?.body?.filename, reportNameFinal, licensorName);
            this.props?.handleLetterGenerated();
            if((letter_type === "CONFIRM" && this?.props?.lic?.confirmation_date ===null) || (letter_type === "LICENSE_REQUEST" && this?.props?.lic?.license_request_date ===null) || (letter_type === "QUOTE REQUEST" && this?.props?.lic?.quote_request_date ===null ) || (letter_type === "USE CONFIRM" && this?.props?.lic?.use_confirmation_date ===null)){
              this.props?.getClearanceLicensorsDate(letter_type);
            }
          } else {
            this.setState({ isPosting: false, postInitiated: false })
          }
        }
      })
    }

    getDataForExistingLetters = (finalArr) => {
      if (!finalArr) {
        return;
      }
      Object.keys(finalArr).map(obj => {
        if (finalArr[obj] === undefined) {
          finalArr[obj] = null;
        }
      })
      let encryptTedStr = btoa(JSON.stringify(finalArr));
      let load = {
        'payload': 'checkExistingLetters',
        'searchJson': encryptTedStr,
        'showHtml': 0,
        'encryptionType': 1,
        'tenantId': this.props?.userContext?.active_tenant?.tenant_id,
        'divisionId': this.props?.division_id,
      }
      return clearTrackService.getDataWS(load)
    }

    generateReport = (reportName, searchPayload) => {
      if (!searchPayload || !reportName) {
        return;
      }
      Object.keys(searchPayload).map(obj => {
        if (searchPayload[obj] === undefined) {
          searchPayload[obj] = null;
        }
      })
      let encryptTedStr = btoa(JSON.stringify(searchPayload));
      let load = {
        'payload': 'exportDocx',
        'reportName': reportName,
        'searchJson': encryptTedStr,
        'showHtml': 0,
        'encryptionType': 1,
        'tenantId': this.props?.userContext?.active_tenant?.tenant_id,
        'divisionId': this.props?.division_id,
      }
      return clearTrackService.getDataWS(load)
    }

    downloadUrl = (url, reportName, tabName, licensorName) => {
      let count = this.state.count
      count = count + 1;
      this.setState({ count })
      if (url && !this.state.licensorLetterData?.email_letters) {
        fetch(url).then(response => response.blob()).then(blob => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute('download', reportName);
          this.notificationComponent(true, 'success', tabName, licensorName)
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
      }
      if (url && this.state.licensorLetterData?.email_letters === 1) {
        fetch(url).then(response => response.blob()).then(blob => {
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute('download', reportName);
          this.notificationComponent(true, 'email', tabName, licensorName)
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
      }
      if (this.state.count === letterCount) {
        this.props?.handleClose();
      }
    }



    quoteRequest = () => {
      return (
        <div>
          <MDBRow>
            <MDBCol md={5}>
              <BasicCheckbox
                label="Urgent"
                id={"urgent"}
                checked={this.state.licensorLetterData?.urgent}
                onChange={(e) => {
                  this.handleChange("urgent", e.target.checked ? 1 : 0);
                }}
              />
              <BasicCheckbox
                label="Revised"
                id={"revised"}
                checked={this.state.licensorLetterData?.revised}
                onChange={(e) => {
                  this.handleChange("revised", e.target.checked ? 1 : 0);
                }}
              />
              <BasicCheckbox
                label="Include “Exec Producer Card” Language"
                id={"execProducerCard"}
                checked={this.state.licensorLetterData?.execProducerCard}
                onChange={(e) => {
                  this.handleChange(
                    "execProducerCard",
                    e.target.checked ? 1 : 0
                  );
                }}
              />

              <BasicCheckbox
                label="Include Ad Card Language"
                id={"includeAdCard"}
                checked={this.state.licensorLetterData?.includeAdCard}
                onChange={(e) => {
                  this.handleChange("includeAdCard", e.target.checked ? 1 : 0);
                }}
              />

              <BasicCheckbox
                label="Include Pilot Quote Extension Language"
                id={"includePilotQuote"}
                checked={this.state.licensorLetterData?.includePilotQuote}
                onChange={(e) => {
                  this.handleChange(
                    "includePilotQuote",
                    e.target.checked ? 1 : 0
                  );
                }}
              />
              <BasicCheckbox
                label="Include Pre-air Marketing Language"
                id={"includePreAirMarket"}
                checked={this.state.licensorLetterData?.includePreAirMarketing}
                onChange={(e) => {
                  this.handleChange(
                    "includePreAirMarketing",
                    e.target.checked ? 1 : 0
                  );
                }}
              />
              <BasicCheckbox
                label="Include Quote Extension for Mid Season Language"
                id={"includeQuoteMidSeason"}
                checked={this.state.licensorLetterData?.includeQuoteMidSeason}
                onChange={(e) => {
                  this.handleChange(
                    "includeQuoteMidSeason",
                    e.target.checked ? 1 : 0
                  );
                }}
              />
              <BasicCheckbox
                label="Include Proposed Fees"
                id={"includeProposedFees"}
                checked={this.state.licensorLetterData?.includeProposedFees}
                onChange={(e) => {
                  this.handleChange(
                    "includeProposedFees",
                    e.target.checked ? 1 : 0
                  );
                }}
              />
              <BasicCheckbox
                label="User Defined Language"
                id={"userDefinedLanguage"}
                checked={this.state.licensorLetterData?.userDefinedLanguage}
                onChange={(e) => {
                  this.handleChange(
                    "userDefinedLanguage",
                    e.target.checked ? 1 : 0
                  );
                }}
              />
            </MDBCol>
            <MDBCol md={4}>
              {this.state.licensorLetterData?.urgent ? (
                <BasicTextArea
                  label={""}
                  rows={3}
                  onHoverText="-Urgent Notes-"
                  value={this.state.licensorLetterData?.urgentNotes || null }
                  onChange={(e) =>
                    this.handleChange(
                      "urgentNotes",
                      e.target.value?.length ? e.target?.value : null
                    )
                  }
                />
              ) : null}
              {this.state.licensorLetterData?.revised ? (
                <BasicTextArea
                  label={""}
                  rows={3}
                  onHoverText="-Revised Notes-"
                  value={this.state.licensorLetterData?.revisedNotes || null}
                  onChange={(e) =>
                    this.handleChange(
                      "revisedNotes",
                      e.target.value?.length ? e.target?.value : null
                    )
                  }
                />
              ) : null}
              {this.state.licensorLetterData?.includeAdCard ? (
                <BasicCheckbox
                  label="Alt 6-Yr Ad Card"
                  id={"altAdCard"}
                  checked={this.state.licensorLetterData?.altAdCard}
                  onChange={(e) => {
                    this.handleChange("altAdCard", e.target.checked ? 1 : 0);
                  }}
                />
              ) : null}
            </MDBCol>
            <MDBCol>
              {this.state.licensorLetterData?.email_letters ?
                (
                  <>
                    <BasicCheckbox
                      label="Attach W8BEN"
                      id={"attachw8ben"}
                      checked={this.state.licensorLetterData?.attach_w8ben}
                      onChange={(e) => {
                        this.handleChange("attach_w8ben", e.target.checked ? 1 : 0);
                      }}
                    />
                    <BasicCheckbox
                      label="Attach W8BEN-E"
                      id={"attachw8ben-e"}
                      checked={this.state.licensorLetterData?.attach_w8ben_e}
                      onChange={(e) => {
                        this.handleChange("attach_w8ben_e", e.target.checked ? 1 : 0);
                      }}
                    />
                    <BasicCheckbox
                      label="Attach W9"
                      id={"attachw9"}
                      checked={this.state.licensorLetterData?.attach_w9}
                      onChange={(e) => {
                        this.handleChange("attach_w9", e.target.checked ? 1 : 0);
                      }}
                    />
                  </>
                )
                : null}
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol
              md={12}
              id="licensorDropDown"
              className={`${this.state.licensorLetterData.quoteRequest_list?.length === 0
                ? "searchSelectHeight licensorSearchSelect"
                : "licensorSearchSelect"
                }`}
            >
              <SearchSelectField
                label={""}
                width={"100%"}
                id={"quoteRequestSelect"}
                disabled={
                  this.state.licensorLetterData.userDefinedLanguage
                    ? false
                    : true
                }
                multiple={true}
                placeHolderText="- Select -"
                value={
                  this.state.licensorLetterData.quoteRequest_list?.length > 0
                    ? this.state.licensorLetterData.quoteRequest_list.map(
                      (item) => {
                        let newObj = { ...item }
                        newObj.value = item.value,
                          newObj.text = item.text
                        return newObj;
                      }
                    )
                    : []
                }
                options={this.state.quoteRequestOptionList || []}
                onChange={(e, newValue) => {
                  this.onChangeCallBack(
                    "quoteRequest_list",
                    newValue,
                    e.target.value
                  );
                }}
              />
            </MDBCol>
          </MDBRow>
        </div>
      );
    };

    confirmation = () => {
      return (
        <div>
          <MDBRow>
            <MDBCol md={2}>
              <BasicCheckbox
                label="Revised"
                id={"confirmationRevised"}
                checked={this.state.licensorLetterData?.confirmationRevised}
                onChange={(e) => {
                  this.handleChange(
                    "confirmationRevised",
                    e.target.checked ? 1 : 0
                  );
                }}
              />
              {this.state.licensorLetterData?.confirmationRevised ? (
                <BasicTextArea
                  label={""}
                  rows={2}
                  value={this.state.licensorLetterData?.confirmRevisedNotes}
                  onChange={(e) =>
                    this.handleChange(
                      "confirmRevisedNotes",
                      e.target.value?.length ? e.target?.value : null
                    )
                  }
                />
              ) : null}
            </MDBCol>
            <MDBCol md={3}>
              <BasicCheckbox
                label="User Defined Language"
                id={"confirmUserDefined"}
                checked={this.state.licensorLetterData?.confirmUserDefined}
                onChange={(e) => {
                  this.handleChange(
                    "confirmUserDefined",
                    e.target.checked ? 1 : 0
                  );
                }}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol
              md={12}
              id="licensorDropDown"
              className={`${this.state.licensorLetterData.confirmationLicensor_list
                ?.length === 0
                ? "searchSelectHeight licensorSearchSelect"
                : "licensorSearchSelect"
                }`}
            >
              <SearchSelectField
                label={""}
                width={"100%"}
                id={"confirmationSelect"}
                disabled={
                  this.state.licensorLetterData?.confirmUserDefined
                    ? false
                    : true
                }
                multiple={true}
                placeHolderText="- Select -"
                value={
                  this.state.licensorLetterData.confirmationLicensor_list
                    ?.length > 0
                    ? this.state.licensorLetterData.confirmationLicensor_list.map(
                      (item) => {
                        let newObj = { ...item }
                        newObj.value = item.value,
                          newObj.text = item.text
                        return newObj;
                      }
                    )
                    : []
                }
                options={this.state.confirmationOptionList || []}
                onChange={(e, newValue) => {
                  this.onChangeCallBack(
                    "confirmationLicensor_list",
                    newValue,
                    e.target.value
                  );
                }}
              />
            </MDBCol>
          </MDBRow>
        </div>
      );
    };

    useConfirmation = ()=>{
      return (
        <>
        <MDBRow>
        <MDBCol md={2}>
            <BasicCheckbox
              label="Revised"
              id={"useConfirmationRevised"}
              checked={this.state.licensorLetterData?.useConfirmationRevised}
              onChange={(e) => {
                this.handleChange(
                  "useConfirmationRevised",
                  e.target.checked ? 1 : 0
                );
              }}
            />
            {this.state.licensorLetterData?.useConfirmationRevised && (
                <BasicTextArea
                  label={""}
                  rows={2}
                  value={this.state.licensorLetterData?.useConfirmRevisedNotes}
                  onChange={(e) =>
                    this.handleChange(
                      "useConfirmRevisedNotes",
                      e.target.value?.length ? e.target?.value : null
                    )
                  }
                />
              )}
          </MDBCol>  
          <MDBCol md={3}>
              <BasicCheckbox
                label="User Defined Language"
                id={"useConfirmUserDefined"}
                checked={this.state.licensorLetterData?.useConfirmUserDefined}
                onChange={(e) => {
                  this.handleChange(
                    "useConfirmUserDefined",
                    e.target.checked ? 1 : 0
                  );
                }}
              />
            </MDBCol> 
        </MDBRow>
        <MDBRow>
            <MDBCol
              md={12}
              id="licensorDropDown"
              className={`${this.state.licensorLetterData.useConfirmationLicensor_list
                ?.length === 0
                ? "searchSelectHeight licensorSearchSelect"
                : "licensorSearchSelect"
                }`}
            >
              <SearchSelectField
                label={""}
                width={"100%"}
                id={"useConfirmationSelect"}
                disabled={
                  this.state.licensorLetterData?.useConfirmUserDefined
                    ? false
                    : true
                }
                multiple={true}
                placeHolderText="- Select -"
                value={
                  this.state.licensorLetterData.useConfirmationLicensor_list
                    ?.length > 0
                    ? this.state.licensorLetterData.useConfirmationLicensor_list.map(
                      (item) => {
                        let newObj = { ...item }
                        newObj.value = item.value,
                          newObj.text = item.text
                        return newObj;
                      }
                    )
                    : []
                }
                options={this.state.useConfirmationOptionList || []}
                onChange={(e, newValue) => {
                  this.onChangeCallBack(
                    "useConfirmationLicensor_list",
                    newValue,
                    e.target.value
                  );
                }}
              />
            </MDBCol>
          </MDBRow>
        </>
      )
    }

    licenseRequest = () => {
      let LROptions = [];
      let licensorInfor = [...this.state.licensorInfor];
      for (let i = 0; i < licensorInfor[0].licensors_list?.length; i++) {
        if (this.state.isMainTitle === "mainTitle") {
          for (let j = 0; j < licensorInfor[0].licensors_list[i].main_title_check_request?.length; j++) {
            let newItem = {}
            if(licensorInfor[0].licensors_list[i].main_title_check_request[j].requested_date){
              newItem.text = licensorInfor[0].licensors_list[i].main_title_check_request[j].payee_remit_to_name +" - "+ getFormattedDate(licensorInfor[0].licensors_list[i].main_title_check_request[j].requested_date)
            }else{
              newItem.text = licensorInfor[0].licensors_list[i].main_title_check_request[j].payee_remit_to_name
            }
            newItem.licensor_name = licensorInfor[0].licensors_list[i].licensor_name,
            newItem.main_title_id = licensorInfor[0].main_title_id,
            newItem.main_title_licensor_id = licensorInfor[0].licensors_list[i].main_title_licensor_id,
            newItem.value = licensorInfor[0].licensors_list[i].main_title_check_request[j].main_title_check_request_id,
            newItem.check_request_id = licensorInfor[0].licensors_list[i].main_title_check_request[j].main_title_check_request_id
            LROptions.push(newItem);
          }
        }}
      if (this.state.checkRequestReportSelect) {
        let defaultValue = LROptions?.filter(item => item.licensor_name === this.state.licensorLetterData?.licensor_list[0]?.text && item.main_title_licensor_id === this.state.licensorLetterData?.licensor_list[0]?.clearance_licensor_id)
        let newValue = []
        if (defaultValue?.length === 1) {
          let licensorLetterData = this.state.licensorLetterData
          newValue = defaultValue?.map((item) => ({
            value: item.value,
            text: item.text,
            show_season_id: item?.show_season_id
          }));
            this.setState((prevState) => ({
              licensorLetterData: {
                ...prevState.licensorLetterData,
                ["LicenseRequestValue"]: newValue,
              },
              checkRequestReportSelect: false,
              values: LROptions
            }))
        }
      }
      return (
        <div>
          <MDBRow>
            <MDBCol md={2}>
              <BasicCheckbox
                label="Revised"
                id={"licRequestRevised"}
                checked={this.state.licensorLetterData?.licRequestRevised}
                onChange={(e) => {
                  this.handleChange(
                    "licRequestRevised",
                    e.target.checked ? 1 : 0
                  );
                }}
              />
              <BasicCheckbox
                label="User Defined Language"
                id={"licRequestUserDefined"}
                checked={this.state.licensorLetterData?.licRequestUserDefined}
                onChange={(e) => {
                  this.handleChange(
                    "licRequestUserDefined",
                    e.target.checked ? 1 : 0
                  );
                }}
              />
            </MDBCol>
            <MDBCol md={2}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                id="radio-group-height"
                onChange={(e) => this.handleChange("licenseRequestCheck", e.target.value)}
                value={this.state.licensorLetterData?.licenseRequestCheck}
              // onChange={handleChange}
              >
                <FormControlLabel
                  className="LabelSize"
                  value="TheyPay"
                  control={<Radio size="small" />}
                  label="They Pay"
                />
                <FormControlLabel
                  className="LabelSize"
                  value="N/A"
                  control={<Radio size="small" />}
                  label="N/A"
                />
              </RadioGroup>
            </MDBCol>
            {this.state.isMainTitle === "mainTitle" && 
            <MDBCol md={3} className="LicenseRequest">
            <span className="Font6">Payee</span>
              <SearchSelectField
                id={"LRkey18"}
                size="small"
                defaultMenuText={"Select"}
                isMandatory={true}
                showMandatory={this.state.postInitiated}
                mandatoryWarning={messages.mandatoryWarning}
                multiple={true}
                placeholder={this.state.licensorLetterData?.LicenseRequestValue?.length > 0 ? "" : "- Select a Payee -"}
                value={this.state.licensorLetterData?.LicenseRequestValue?.length > 0
                    ? this.state.licensorLetterData?.LicenseRequestValue.map((item) => ({
                        value: item.value,
                        text: item.text,
                      })): []}
                options={LROptions?.filter(item => item.licensor_name === this.state.licensorLetterData?.licensor_list[0]?.text && item.main_title_licensor_id === this.state.licensorLetterData?.licensor_list[0]?.clearance_licensor_id) || []}
                onChange={(e, newValue) => {this.onChangeCallBack(
                      "LicenseRequestValue",
                      newValue,
                      e.target.value,
                      LROptions
                    )}}
              />
            </MDBCol>}
            <MDBCol md={4}>
              {this.state.licensorLetterData?.licRequestRevised ? (
                <BasicTextArea
                  label={""}
                  rows={3}
                  onHoverText="-Revised Notes-"
                  value={this.state.licensorLetterData?.licRequestRevisedNotes || null}
                  onChange={(e) =>
                    this.handleChange(
                      "licRequestRevisedNotes",
                      e.target.value?.length ? e.target?.value : null
                    )
                  }
                />
              ) : null}
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol
              md={12}
              id="licensorDropDown"
              className={`${this.state.licensorLetterData.licenseRequest_list?.length === 0
                ? "searchSelectHeight licensorSearchSelect"
                : "licensorSearchSelect"
                }`}
            >
              <SearchSelectField
                label={""}
                width={"100%"}
                id={"licRequestSelect"}
                disabled={
                  this.state.licensorLetterData?.licRequestUserDefined
                    ? false
                    : true
                }
                multiple={true}
                placeHolderText="- Select -"
                value={
                  this.state.licensorLetterData.licenseRequest_list?.length > 0
                    ? this.state.licensorLetterData.licenseRequest_list.map(
                      (item) => {
                        let newObj = { ...item }
                        newObj.value = item.value,
                          newObj.text = item.text
                        return newObj;
                      }
                    )
                    : []
                }
                options={this.state.licRequestOptionList || []}
                onChange={(e, newValue) => {
                  this.onChangeCallBack(
                    "licenseRequest_list",
                    newValue,
                    e.target.value
                  );
                }}
              />
            </MDBCol>
          </MDBRow>
        </div>
      );
    };

    newLicense = () => {
      return (
        <div>
          <MDBRow></MDBRow>
          {this.state.clearanceCOAOption  ? this.getCOALetterOption('license') :<MDBRow>
            <MDBCol className="d-flex">
              <MDBCol>
                <Radio
                  name="radio-buttons"
                  id="N/A"
                  //label="N/A"
                  checked={this.state.licensorLetterData.licenseCheck === "N/A"}
                  onChange={(e) => this.handleChange("licenseCheck", e.target.value)}
                  value={"N/A"} />
              </MDBCol>
              <MDBCol className="checkBoxNA">
                <BasicLabel text="N/A" />
              </MDBCol>
            </MDBCol>
          </MDBRow>}
        </div >
      );
    };


    firstLetterLower = (letterName) => {
      return letterName?.charAt(0)?.toLowerCase() + letterName?.slice(1)
    }

    getCOALetterOption = (lettername) => {
      return (
        <>
          <MDBRow>
            <MDBCol md = {6}>
                <BasicCheckbox
                  label="CO-COPMSER-MUSIC & LYRICS"
                  id={lettername+"CO-COPMSER-MUSIC & LYRICS"}
                  checked={this.state.licensorLetterData?.[lettername+'coa_type'] === 'ccmal'}
                  onChange={(e) => this.handleChange(lettername+'coa_type', e.target.checked ? 'ccmal' : null)}
                />
            </MDBCol>
            {/* {this.state.licensorLetterData?.[lettername+'coa_type'] === 'ccmal' && <MDBCol md = {6}>
              <BasicTextArea
                  label={"Co-Composer(s) (leave blank if already entered in Cleartrack"}
                  rows={3}
                  value={this.licensorLetterData?.[lettername+'coa_text'] }
                  onChange={(e) => this.handleChange(lettername+'coa_text', e.target.value?.length > 0 ? e.target?.value : null)}
                />
            </MDBCol>} */}
          </MDBRow>
          <MDBRow>
            <MDBCol md = {6}>
                <BasicCheckbox
                  label="CO-COPMSER-ORINIGAL LYRICS"
                  id={lettername+"CO-COPMSER-ORINIGAL LYRICS"}
                  checked={this.state.licensorLetterData?.[lettername+'coa_type']  === 'ccol'}
                  onChange={(e) => this.handleChange(lettername+'coa_type', e.target.value?.length > 0? 'ccol' : null)}
                />
            </MDBCol>
            {/* <MDBCol md = {6}>
              <BasicTextArea
                  label={"Co-Composer(s) (leave blank if already entered in Cleartrack"}
                  rows={3}
                  value={this.licensorLetterData?.[lettername+'coa_text'] }
                  onChange={(e) => this.handleChange(lettername+'coa_text', e.target.value?.length > 0 ? e.target?.value : null)}
                />
            </MDBCol> */}
          </MDBRow>
          <MDBRow>
            <MDBCol md = {6}>
                <BasicCheckbox
                  label="CO-COPMSER-ORIGINAL MUSIC"
                  id={lettername+"CO-COPMSER-ORIGINAL MUSIC"}
                  checked={this.state.licensorLetterData?.[lettername+'coa_type'] === 'ccom'}
                  onChange={(e) => this.handleChange(lettername+'coa_type', e.target.checked ? 'ccom' : null)}
                />
            </MDBCol>
            {/* <MDBCol md = {6}>
              <BasicTextArea
                  label={"Co-Composer(s) (leave blank if already entered in Cleartrack"}
                  rows={3}
                  value={this.licensorLetterData?.[lettername+'coa_text'] }
                  onChange={(e) => this.handleChange(lettername+'coa_text', e.target.value?.length ? e.target?.value : null)}
                />
            </MDBCol> */}
          </MDBRow>
          <MDBRow>
            <MDBCol md = {6}>
                <BasicCheckbox
                  label="SINGLE COMPOSER-PD ARRANGEMENT"
                  id={lettername+"SINGLE COMPOSER-PD ARRANGEMENT"}
                  checked={this.state.licensorLetterData?.[lettername+'coa_type']  === 'scpda'}
                  onChange={(e) => this.handleChange(lettername+'coa_type', e.target.checked ? 'scpda' : null)}
                />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md = {6}>
                <BasicCheckbox
                  label="SINGLE COMPOSER MUSIC & LYRICS"
                  id={lettername+"SINGLE COMPOSER MUSIC & LYRICS"}
                  checked={this.state.licensorLetterData?.[lettername+'coa_type'] === 'scml'}
                  onChange={(e) => this.handleChange(lettername+'coa_type', e.target.checked ? 'scml' : null)}
                />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md = {6}>
                <BasicCheckbox
                  label="SINGLE COMPOSER-MUSIC"
                  id={lettername+"SINGLE COMPOSER-MUSIC"}
                  checked={this.state.licensorLetterData?.[lettername+'coa_type'] === 'scm'}
                  onChange={(e) => this.handleChange(lettername+'coa_type', e.target.checked ? 'scm' : null)}
                />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol md = {6}>
                <BasicCheckbox
                  label="SINGLE COMPOSER-MUSIC,LYRICS & RECORDING"
                  id={lettername+"SINGLE COMPOSER-MUSIC,LYRICS & RECORDING"}
                  checked={this.state.licensorLetterData?.[lettername+'coa_type'] === 'scmlr'}
                  onChange={(e) => this.handleChange(lettername+'coa_type', e.target.checked ? 'scmlr' : null)}
                />
            </MDBCol>
          </MDBRow>
        </>
      )
    }

    licenseCoverLetter = () => {
      return (
        <div>
          { this.state.clearanceCOAOption  ? this.getCOALetterOption('licenseCoverLetter') :<>
          <MDBRow>
            <MDBCol md={5}>
              <span className="Font6">Signature</span>
              <SelectField
                id={"key15"}
                size="small"
                defaultMenuText={"Select"}
                value={this.state.licensorLetterData?.coverLetterSignature}
                options={this.state.signatureOptions}
                onChange={(e) => this.handleChangeCheckRequest("coverLetterSignature", e.target.value, this.state.signatureOptions)}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>

            <MDBCol md={7}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                id="radio-group-height"
                value={this.state.licensorLetterData?.licenseCoverLetterLetterType}
                onChange={(e) => this.handleChange("licenseCoverLetterLetterType", e.target.value)}
              >
                <FormControlLabel
                  className="LabelSize"
                  value="WB Internal - Countersignature"
                  control={<Radio size="small" />}
                  label="WB Internal - Countersignature"
                />
                <FormControlLabel
                  className="LabelSize"
                  value="External License - Countersignature"
                  control={<Radio size="small" />}
                  label="External License - Countersignature"
                />
                <FormControlLabel
                  className="LabelSize"
                  value="Revisions Requested"
                  control={<Radio size="small" />}
                  label="Revisions Requested"
                />
                <FormControlLabel
                  className="LabelSize"
                  value="External License - Countersignature and Initials"
                  control={<Radio size="small" />}
                  label="External License - Countersignature and Initials"
                />
                <FormControlLabel
                  className="LabelSize"
                  value="Fully Executed"
                  control={<Radio size="small" />}
                  label="Fully Executed"
                />
              </RadioGroup>
            </MDBCol>
          </MDBRow>
          </>}
        </div>
      );
    };

    producerMemo = () => {
      let memoOptions = [];
      let licensorInfor = [...this.state.licensorInfor];
      let producerMemoOptions = [{
        text: this.props?.song?.song_title ,//this.props?.licensorsInfo[0]?.songs_list[0]?.song_title,
        value: this.props?.song?.song_id //this.props?.licensorsInfo[0]?.songs_list[0]?.song_id
      }]
      if (this.state.checkRequestReportSelect) {
        let defaultValue = producerMemoOptions
        let newValue = []
        if (defaultValue?.length === 1) {
          let licensorLetterData = this.state.licensorLetterData
          newValue = defaultValue?.map((item) => ({
            value: item.value,
            text: item.text,
          }));
          this.setState((prevState) => ({
            licensorLetterData: {
              ...prevState.licensorLetterData,
              ["producerMemo"]: newValue,
            },
            checkRequestReportSelect: false,
          }))
        }
      }
      return (
        <div>
          <MDBRow>
            <MDBCol md={2} className="checkRequest">
              <SearchSelectField
                id={"key18"}
                size="small"
                defaultMenuText={"Select"}
                isMandatory={true}
                showMandatory={this.state.postInitiated}
                mandatoryWarning={messages.mandatoryWarning}
                multiple={true}
                placeHolderText="- Select -"
                value={this.state.licensorLetterData?.producerMemo
                  ?.length > 0
                  ? this.state.licensorLetterData?.producerMemo.map(
                    (item) => ({
                      value: item.value,
                      text: item.text,
                    })
                  )
                  : []
                }
                options={producerMemoOptions || []}
                // options={memoOptions?.filter(item => item.licensor_name === this.state.licensorLetterData?.licensor_list[0]?.text) || []}
                onChange={(e, newValue) => {
                  this.onChangeCallBack(
                    "producerMemo",
                    newValue,
                    e.target.value
                  )
                }}
              />
            </MDBCol>
          </MDBRow>
        </div>
      );
    };

    checkRequestOrAP = (letter_name) => {
      let options = [];
      let licensorInfor = [...this.state.licensorInfor];
      for (let i = 0; i < licensorInfor[0].licensors_list?.length; i++) {
        if (this.state.isMainTitle === "mainTitle") {
          for (let j = 0; j < licensorInfor[0].licensors_list[i].main_title_check_request?.length; j++) {
            let newItem = {}
            newItem.licensor_name = licensorInfor[0].licensors_list[i].licensor_name,
              newItem.main_title_id = licensorInfor[0].main_title_id,
              newItem.main_title_licensor_id = licensorInfor[0].licensors_list[i].main_title_licensor_id,
              newItem.value = licensorInfor[0].licensors_list[i].main_title_check_request[j].main_title_check_request_id,
              newItem.text = licensorInfor[0].licensors_list[i].main_title_check_request[j].payee_remit_to_name,
              newItem.check_request_id = licensorInfor[0].licensors_list[i].main_title_check_request[j].main_title_check_request_id
            options.push(newItem);
          }
        } else {
          for (let j = 0; j < licensorInfor[0].licensors_list[i].clearance_check_request?.length; j++) {
            let newItem = {}
            newItem.licensor_name = licensorInfor[0].licensors_list[i].licensor_name,
              newItem.clearance_id = licensorInfor[0].clearance_id,
              // newItem.value = licensorInfor[0].licensors_list[i].clearance_licensor_id,
              newItem.clearance_licensor_id = licensorInfor[0].licensors_list[i].clearance_licensor_id,
              newItem.value = licensorInfor[0].licensors_list[i].clearance_check_request[j].clearance_check_request_id,
              newItem.text = licensorInfor[0].licensors_list[i].clearance_check_request[j].payee_remit_to_name,
              newItem.check_request_id = licensorInfor[0].licensors_list[i].clearance_check_request[j].clearance_check_request_id
            options.push(newItem);
          }
        }
      }
      if (this.state.checkRequestReportSelect) {
        let defaultValue = this.state.isMainTitle === "mainTitle" ? options?.filter(item => item.licensor_name === this.state.licensorLetterData?.licensor_list[0]?.text && item.main_title_licensor_id === this.state.licensorLetterData?.licensor_list[0]?.clearance_licensor_id) : options?.filter(item => item.licensor_name === this.state.licensorLetterData?.licensor_list[0]?.text && item.clearance_licensor_id === this.state.licensorLetterData?.licensor_list[0]?.clearance_licensor_id)
        let newValue = []
        if (defaultValue?.length === 1) {
          let licensorLetterData = this.state.licensorLetterData
          newValue = defaultValue?.map((item) => ({
            value: item.value,
            text: item.text,
            show_season_id: item?.show_season_id
            // licensor_type: item.licensor_type,
            // clearance_licensor_id: item.clearance_licensor_id
          }));
          letter_name === "Check Request" ?
            this.setState((prevState) => ({
              licensorLetterData: {
                ...prevState.licensorLetterData,
                ["checkRequestValue"]: newValue,
              },
              checkRequestReportSelect: false,
              values: options
            })) :
            this.setState((prevState) => ({
              licensorLetterData: {
                ...prevState.licensorLetterData,
                ["checkRequestAPValue"]: newValue,
              },
              checkRequestReportSelect: false,
              values: options
            }))
        }
      }
      return (
        <div>
          {this.state.clearanceCOAOption && this.props?.mainTitle  !== 'mainTitle' ? this.getCOALetterOption(this.firstLetterLower(letter_name?.replaceAll(' ',''))) : <MDBRow>
            <MDBCol md={2} className="checkRequest">
              {/* <SelectField
                id={"key15"}
                size="small"
                defaultMenuText={"Select"}
                value={letter_name === "Check Request" ? this.state.licensorLetterData?.checkRequestValue : this.state.licensorLetterData?.checkRequestAPValue}
                options={options?.filter(item => item.licensor_name === this.state.licensorLetterData?.licensor_list[0]?.text)}
                onChange={(e) =>
                  letter_name === "Check Request" ? this.handleChangeCheckRequest("checkRequestValue", e.target.value, options) : this.handleChangeCheckRequest("checkRequestAPValue", e.target.value, options)
                }
              /> */}
              <span className="Font6">Payee</span>
              <SearchSelectField
                id={"key18"}
                size="small"
                defaultMenuText={"Select"}
                // disabled={
                //   this.state.licensorLetterData?.checkTransmittalUserDefined
                //     ? false
                //     : true
                // }
                isMandatory={true}
                showMandatory={this.state.postInitiated}
                mandatoryWarning={messages.mandatoryWarning}
                multiple={true}
                placeholder={letter_name === "Check Request" ? this.state.licensorLetterData?.checkRequestValue
                  ?.length > 0 ? "" : "- Select a Payee -" : this.state.licensorLetterData?.checkRequestAPValue
                    ?.length > 0 ? "" : "- Select a Payee -"}
                value={letter_name === "Check Request" ?
                  this.state.licensorLetterData?.checkRequestValue
                    ?.length > 0
                    ? this.state.licensorLetterData?.checkRequestValue.map(
                      (item) => ({
                        value: item.value,
                        text: item.text,
                      })
                    )
                    : []
                  :
                  this.state.licensorLetterData?.checkRequestAPValue
                    ?.length > 0
                    ? this.state.licensorLetterData?.checkRequestAPValue.map(
                      (item) => ({
                        value: item.value,
                        text: item.text,
                      })
                    )
                    : []
                }
                options={this.state.isMainTitle === "mainTitle" ? options?.filter(item => item.licensor_name === this.state.licensorLetterData?.licensor_list[0]?.text && item.main_title_licensor_id === this.state.licensorLetterData?.licensor_list[0]?.clearance_licensor_id) : options?.filter(item => item.licensor_name === this.state.licensorLetterData?.licensor_list[0]?.text && item.clearance_licensor_id === this.state.licensorLetterData?.licensor_list[0]?.clearance_licensor_id) || []}
                onChange={(e, newValue) => {
                  letter_name === "Check Request" ?
                    this.onChangeCallBack(
                      "checkRequestValue",
                      newValue,
                      e.target.value,
                      options
                    )
                    :
                    this.onChangeCallBack(
                      "checkRequestAPValue",
                      newValue,
                      e.target.value,
                      options
                    )
                }}
              />
            </MDBCol>
          </MDBRow>}
        </div>
      );
    };
    checkTransmittalOrAP = (letter_name) => {
      let ctOptions = []
      let licensorInfor = [...this.state.licensorInfor];
      for (let i = 0; i < licensorInfor[0].licensors_list?.length; i++) {
        if (this.state.isMainTitle === "mainTitle") {
          for (let j = 0; j < licensorInfor[0].licensors_list[i].main_title_check_request?.length; j++) {
            let newItem = {}
            newItem.licensor_name = licensorInfor[0].licensors_list[i].licensor_name,
              newItem.main_title_id = licensorInfor[0].main_title_id,
              newItem.main_title_licensor_id = licensorInfor[0].licensors_list[i].main_title_licensor_id,
              newItem.value = licensorInfor[0].licensors_list[i].main_title_check_request[j].main_title_check_request_id,
              newItem.check_request_id = licensorInfor[0].licensors_list[i].main_title_check_request[j].main_title_check_request_id,
              newItem.text = licensorInfor[0].licensors_list[i].main_title_check_request[j].payee_remit_to_name
            ctOptions.push(newItem);
          }
        } else {
          for (let j = 0; j < licensorInfor[0].licensors_list[i].clearance_check_request?.length; j++) {
            let newItem = {}
            newItem.licensor_name = licensorInfor[0].licensors_list[i].licensor_name,
              newItem.clearance_id = licensorInfor[0].clearance_id,
              newItem.clearance_licensor_id = licensorInfor[0].licensors_list[i].clearance_licensor_id,
              newItem.value = licensorInfor[0].licensors_list[i].clearance_check_request[j].clearance_check_request_id,
              newItem.check_request_id = licensorInfor[0].licensors_list[i].clearance_check_request[j].clearance_check_request_id,
              newItem.text = licensorInfor[0].licensors_list[i].clearance_check_request[j].payee_remit_to_name
            ctOptions.push(newItem);
          }
        }
      }
      if (this.state.checkRequestReportSelect) {
        let defaultValue = this.state.isMainTitle === "mainTitle" ? ctOptions?.filter(item => item.licensor_name === this.state.licensorLetterData?.licensor_list[0]?.text && item.main_title_licensor_id === this.state.licensorLetterData?.licensor_list[0]?.clearance_licensor_id) : ctOptions?.filter(item => item.licensor_name === this.state.licensorLetterData?.licensor_list[0]?.text && item.clearance_licensor_id === this.state.licensorLetterData?.licensor_list[0]?.clearance_licensor_id)
        let newValue = []
        if (defaultValue?.length === 1) {
          let licensorLetterData = this.state.licensorLetterData
          newValue = defaultValue?.map((item) => ({
            value: item.value,
            text: item.text,
            show_season_id: item?.show_season_id
            // licensor_type: item.licensor_type,
            // clearance_licensor_id: item.clearance_licensor_id
          }));
          letter_name === "Check Transmittal" ?
            this.setState((prevState) => ({
              licensorLetterData: {
                ...prevState.licensorLetterData,
                ["checkTransmittalValue"]: newValue,
              },
              checkRequestReportSelect: false,
              values: ctOptions
            })) :
            this.setState((prevState) => ({
              licensorLetterData: {
                ...prevState.licensorLetterData,
                ["checkTransmittalAPValue"]: newValue,
              },
              checkRequestReportSelect: false,
              values: ctOptions
            }))
        }
      }
      return (
        <>{this.state.clearanceCOAOption && this.props?.mainTitle  !== 'mainTitle' ? this.getCOALetterOption(this.firstLetterLower(letter_name?.replaceAll(' ',''))) : <div>
          <MDBRow>
            <MDBCol md={2} className="checkRequest">
              {/* <SelectField
                id={"key15"}
                size="small"
                defaultMenuText={"Select"}
                value={letter_name === "Check Transmittal" ? this.state.licensorLetterData?.checkTransmittalValue : this.state.licensorLetterData?.checkTransmittalAPValue}
                options={ctOptions?.filter(item => item.licensor_name === this.state.licensorLetterData?.licensor_list[0]?.text)}
                onChange={(e) =>
                  letter_name === "Check Transmittal" ? this.handleChangeCheckRequest("checkTransmittalValue", e.target.value, ctOptions) : this.handleChangeCheckRequest("checkTransmittalAPValue", e.target.value, ctOptions)
                }
              /> */}
              <span className="Font6">Payee</span>
              <SearchSelectField
                id={"key19"}
                size="small"
                defaultMenuText={"Select"}
                // disabled={
                //   this.state.licensorLetterData?.checkTransmittalUserDefined
                //     ? false
                //     : true
                // }
                multiple={true}
                isMandatory={true}
                showMandatory={this.state.postInitiated}
                mandatoryWarning={messages.mandatoryWarning}
                placeholder={letter_name === "Check Transmittal" ? this.state.licensorLetterData?.checkTransmittalValue
                  ?.length > 0 ? "" : "- Select a Payee -" : this.state.licensorLetterData?.checkTransmittalAPValue
                    ?.length > 0 ? "" : "- Select a Payee -"}
                value={letter_name === "Check Transmittal" ?
                  this.state.licensorLetterData?.checkTransmittalValue
                    ?.length > 0
                    ? this.state.licensorLetterData?.checkTransmittalValue.map(
                      (item) => ({
                        value: item.value,
                        text: item.text,
                      })
                    )
                    : []
                  :
                  this.state.licensorLetterData?.checkTransmittalAPValue
                    ?.length > 0
                    ? this.state.licensorLetterData?.checkTransmittalAPValue.map(
                      (item) => ({
                        value: item.value,
                        text: item.text,
                      })
                    )
                    : []
                }
                options={this.state.isMainTitle === "mainTitle" ? ctOptions?.filter(item => item.licensor_name === this.state.licensorLetterData?.licensor_list[0]?.text && item.main_title_licensor_id === this.state.licensorLetterData?.licensor_list[0]?.clearance_licensor_id) : ctOptions?.filter(item => item.licensor_name === this.state.licensorLetterData?.licensor_list[0]?.text && item.clearance_licensor_id === this.state.licensorLetterData?.licensor_list[0]?.clearance_licensor_id) || []}
                onChange={(e, newValue) => {
                  letter_name === "Check Transmittal" ?
                    this.onChangeCallBack(
                      "checkTransmittalValue",
                      newValue,
                      e.target.value,
                      ctOptions
                    )
                    :
                    this.onChangeCallBack(
                      "checkTransmittalAPValue",
                      newValue,
                      e.target.value,
                      ctOptions
                    )
                }}
              />
            </MDBCol>
          </MDBRow>
          <BasicCheckbox
            className={"userDefined"}
            label="User Defined Language"
            id={"checkTransmittalUserDefined"}
            checked={letter_name === "Check Transmittal" ? this.state.licensorLetterData?.checkTransmittalUserDefined : this.state.licensorLetterData?.checkTransmittalAPUserDefined}
            onChange={(e) => {
              this.handleChange(
                letter_name === "Check Transmittal" ?
                  "checkTransmittalUserDefined" : "checkTransmittalAPUserDefined",
                e.target.checked ? 1 : 0
              );
            }}
          />
          <MDBRow className="userDefined">
            <MDBCol md={6}
              id="licensorDropDown"
              className={`${letter_name === "Check Transmittal" ? (this.state.licensorLetterData.checkTransmittalLicensor_list?.length === 0) :
                (this.state.licensorLetterData.checkTransmittalAPLicensor_list?.length === 0)
                  ? "searchSelectHeight licensorSearchSelect"
                  : "licensorSearchSelect"
                }`}
            >
              <SearchSelectField
                id={"key15"}
                size="small"
                defaultMenuText={"Select"}
                disabled={letter_name === "Check Transmittal" ?
                  this.state.licensorLetterData?.checkTransmittalUserDefined
                    ? false
                    : true
                  : this.state.licensorLetterData?.checkTransmittalAPUserDefined
                    ? false
                    : true
                }
                multiple={true}
                placeHolderText="- Select -"
                value={letter_name === "Check Transmittal" ?
                  this.state.licensorLetterData.checkTransmittalLicensor_list
                    ?.length > 0
                    ? this.state.licensorLetterData.checkTransmittalLicensor_list.map(
                      (item) => {
                        let newObj = { ...item }
                        newObj.value = item.value,
                          newObj.text = item.text
                        return newObj;
                      }
                    )
                    : []
                  :
                  this.state.licensorLetterData.checkTransmittalAPLicensor_list
                    ?.length > 0
                    ? this.state.licensorLetterData.checkTransmittalAPLicensor_list.map(
                      (item) => {
                        let newObj = { ...item }
                        newObj.value = item.value,
                          newObj.text = item.text
                        return newObj;
                      }
                    )
                    : []
                }
                options={this.state.checkTransmittalOptionList || []}
                onChange={(e, newValue) => {
                  letter_name === "Check Transmittal" ?
                    this.onChangeCallBack(
                      "checkTransmittalLicensor_list",
                      newValue,
                      e.target.value
                    ) :
                    this.onChangeCallBack(
                      "checkTransmittalAPLicensor_list",
                      newValue,
                      e.target.value
                    )
                }}
              />
            </MDBCol>
          </MDBRow>
        </div>}</>
      );
    };

    noticeOfUse = () => {
      return (
        <div>
          <MDBRow>
            <MDBCol md={2}>
              <BasicCheckbox
                label="Revised"
                id={"noticeOfUseRevised"}
                checked={this.state.licensorLetterData?.noticeOfUseRevised}
                onChange={(e) => {
                  this.handleChange(
                    "noticeOfUseRevised",
                    e.target.checked ? 1 : 0
                  );
                }}
              />
              <BasicCheckbox
                label="User Defined Language"
                id={"noticeOfUseUserDefined"}
                checked={this.state.licensorLetterData?.noticeOfUseUserDefined}
                onChange={(e) => {
                  this.handleChange(
                    "noticeOfUseUserDefined",
                    e.target.checked ? 1 : 0
                  );
                }}
              />
            </MDBCol>
            <MDBCol md={2}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                id="radio-group-height"
                onChange={(e) => this.handleChange("noticeOfUseCheck", e.target.value)}
                value={this.state.licensorLetterData?.noticeOfUseCheck}
              // onChange={handleChange}
              >
                <FormControlLabel
                  className="LabelSize"
                  value="TheyPay"
                  control={<Radio size="small" />}
                  label="They Pay"
                />
                <FormControlLabel
                  className="LabelSize"
                  value="N/A"
                  control={<Radio size="small" />}
                  label="N/A"
                />
              </RadioGroup>
            </MDBCol>
            <MDBCol md={4}>
              {this.state.licensorLetterData?.noticeOfUseRevised ? (
                <BasicTextArea
                  label={""}
                  rows={3}
                  onHoverText="-Revised Notes-"
                  value={this.state.licensorLetterData?.noticeOfUseRevisedNotes || null}
                  onChange={(e) =>
                    this.handleChange(
                      "noticeOfUseRevisedNotes",
                      e.target.value?.length ? e.target?.value : null
                    )
                  }
                />
              ) : null}
            </MDBCol>
            <MDBCol>
              {this.state.licensorLetterData?.email_letters ?
                <BasicCheckbox
                  label="Cue Sheet Will Be Attached"
                  id={"cueSheetWillBeAttached"}
                  checked={this.state.licensorLetterData?.cueSheetWillBeAttached}
                  onChange={(e) => {
                    this.handleChange(
                      "cueSheetWillBeAttached",
                      e.target.checked ? 1 : 0
                    );
                  }}
                /> : null}
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol
              md={12}
              id="licensorDropDown"
              className={`${this.state.licensorLetterData.noticeOfUse_list?.length === 0
                ? "searchSelectHeight licensorSearchSelect"
                : "licensorSearchSelect"
                }`}
            >
              <SearchSelectField
                label={""}
                width={"100%"}
                id={"licRequestSelect"}
                disabled={
                  this.state.licensorLetterData?.noticeOfUseUserDefined
                    ? false
                    : true
                }
                multiple={true}
                placeHolderText="- Select -"
                value={
                  this.state.licensorLetterData.noticeOfUse_list?.length > 0
                    ? this.state.licensorLetterData.noticeOfUse_list.map(
                      (item) => ({
                        value: item.value,
                        text: item.text,
                      })
                    )
                    : []
                }
                options={this.state.noticeOfUseOptionList || []}
                onChange={(e, newValue) => {
                  this.onChangeCallBack(
                    "noticeOfUse_list",
                    newValue,
                    e.target.value
                  );
                }}
              />
            </MDBCol>
          </MDBRow>
        </div>
      );
    };

    onChange = (label, value) => {
      this.setState({
        selectedLetter: value,
      });
    };
    notificationComponent = (value, mode = "fail", letter_name, licensor_name) => {
      switch (mode) {
        case "success": return this.setState({ showSuccessMessage: value, letter_name: letter_name, licensor_name: licensor_name });
        case "fail": return this.setState({ showFailMessage: value });
        case "email": return this.setState({ showEmailSentSuccess: value, letter_name: letter_name, licensor_name: licensor_name })
      }
    }
    handleResetNotify = () => {
      this.setState({ showSuccessMessage: false, showEmailSentSuccess: false });
      if (this.state.saveFlag) {
        this.closeToastMessage();
      }
    }
    closeToastMessage = () => {
      this.setState({ showSuccessMessage: false, showEmailSentSuccess: false });
      if (this.state.saveFlag) {
        this.props?.handleClose();
      }
    }
    onChangeLicense = (label, value) => {
      this.setState({
        newQuoteRequest: value,
      });
    };
    render() {
      let severity = this.state.showFailMessage ? "error" : this.state.showSuccessMessage ? "success" : this.state.showEmailSentSuccess ? "success" : "";
      let messageNotification = this.state.showSuccessMessage ? `${this.state.letter_name}` + " - " + `${this.state.licensor_name}` + "has been downloaded successfully" : this.state.showFailMessage ? "Saved Changes Failed" : this.state.showEmailSentSuccess ? `${this.state.letter_name}` + " - " + `${this.state.licensor_name}` + " letter has been successfully saved to your drafts" : "";
      let isMusicSupervisor = this.props?.userContext?.active_tenant?.division_role?.some(item => item?.role_name === 'Music Supervisor')
      //success modal popup
      return (
        <MDBContainer fluid className="Letters-container">
          <div>
            <NotificationComponent open={this.state.showSuccessMessage || this.state.showFailMessage || this.state.showEmailSentSuccess} message={messageNotification} severity={severity}
              handleResetNotify={this.handleResetNotify.bind(this)} />
          </div>
          <MessageModal
            open={(this.state.statusNotInUsingAlert) || false}
            title={this.state.statusNotInUsingAlert ? "Letter Generation" : null}
            message={`Cannot be created when licensor is not in "USING" status`}
            hideCancel={this.state.statusNotInUsingAlert ? true : false}
            primaryButtonText={"OK"}
            // secondaryButtonText={this.state.statusNotInUsingAlert ? "CANCEL" : null}
            onConfirm={() => this.setState({
              statusNotInUsingAlert: false
            })}
          // handleClose={(e) => this.setState({
          //   statusNotInUsingAlert: false
          // })}
          />
          {/* <MessageModal
            open={this.state.openQuoteRequestMessageModal || false}
            title={"Quote Request Sub Title"}
            message={`Would you like to include subtitling language on the Quote Request`}
            hideCancel={false}
            primaryButtonText={"OK"}
            secondaryButtonText={"CANCEL"}
            onConfirm={() => {
              this.handleChange("userDefinedLanguage",1);
              this.onChangeCallBack("quoteRequest_list", [this.state.quoteRequestOptionList?.find(item => item?.text?.toLowerCase() === 'subtitling permission')],null,null);
              this.setState({openQuoteRequestMessageModal:false})
            }}
            handleClose={(e) => this.setState({
              openQuoteRequestMessageModal: false
            })}
          /> */}
          <div className="spaceBetween">
            <div className="letters-description">
              Build letters concerning the song{" "}
              <span style={{ marginLeft: "3px" }}>
                {this.props?.song?.song_title}
              </span>{" "}
              by {this.props.song?.performer_name}
            </div>
            <div>
              {this.props.EditEmailLetter ? (this.state.tabLabel === "Quote Request" || this.state.tabLabel === "Confirmation" || this.state.tabLabel === "Use Confirmation" ||this.state.tabLabel === "License Request" || this.state.tabLabel === "License" || this.state.tabLabel === "License Cover Letter" || this.state.tabLabel === "Notice Of Use" || this.state.tabLabel === "Producer Memo") ?
                <BasicCheckbox label="Email Letters"
                  id={"email_letters"}
                  checked={this.state.licensorLetterData?.email_letters}
                  onChange={(e) => {
                    this.handleChange("email_letters", e.target.checked ? 1 : 0);
                  }}
                  disabled={this.props?.EditEmailLetter ? false : true }
                />
                : null : null}
            </div>
          </div>
          {this.state.tabLabel !="Producer Memo" ?
          <MDBRow>
            <MDBCol
              md={12}
              id="licensorDropDown"
              className={`${this.state.licensorLetterData.licensor_list?.length === 0
                ? "searchSelectHeight licensorSearchSelect"
                : "licensorSearchSelect"
                }`}
            >
              <SearchSelectField
                label={"Licensors"}
                width={"100%"}
                id={"Licensors"}
                disabled={
                  this.state.licensorOptionList?.length > 0 ? false : true
                }
                multiple={true}
                placeHolderText="- Select -"
                value={
                  this.state.licensorLetterData.licensor_list?.length > 0
                    ? this.state.licensorLetterData.licensor_list.map(
                      (item) => ({
                        value: item.value,
                        text: item.text,
                        licensor_type: item.licensor_type,
                        clearance_licensor_id: item.clearance_licensor_id,
                      })
                    )
                    : [
                      // {
                      //   value: this.props?.lic?.licensor_id,
                      //   text: this.props?.lic?.licensor_name,
                      //   // licensor_type: this.props?.licensor_type,
                      //   // clearance_licensor_id: this.props?.clearance_licensor_id
                      // },
                    ]
                }
                options={this.state.licensorOptionList || []}
                onChange={(e, newValue) => {
                  this.onChangeCallBack(
                    "licensor_list",
                    newValue,
                    e.target.value
                  );
                }}
              />
            </MDBCol>
          </MDBRow>
   :"" }
          <MDBRow className="errorText">
            {(this.state.tabLabel === "Check Request" || this.state.tabLabel === "Check Request A P" || this.state.tabLabel === "Check Transmittal" || this.state.tabLabel === "Check Transmittal A P") ?
             this.state.licensorLetterData.licensor_list?.length > 1 ? 
             "You can choose only one licensor for Check Request letter" :
             this.state.clearanceCOAOption ?
             this.props?.licensorListData?.find(item => item?.licensor_id === this.state.licensorLetterData?.licensor_list[0]?.value)?.clearance_check_request?.length === 0 ? 
             'Please add check requests for selected licensor': null : null : null}
          </MDBRow>
          <MDBRow className="checkBoxes">
            <MDBCol>
              {this.props?.lQCPermissions?.view === 1 && !this.state.clearanceCOAOption && <BasicCheckbox
                id={"quoteRequest"}
                checked={this.state.licensorLetterData?.quoteRequest}
                onChange={(e) => {
                  if(this.props?.mainTitle === 'clearence' && 
                  this.props?.song?.abbreviation?.toString() === "Bkg. Voc."||  this.props?.song?.abbreviation?.toString() === "Bkg. Voc. / Main Title"||  this.props?.song?.abbreviation?.toString() ===  "Vis. Voc. / Main Title"||  this.props?.song?.abbreviation?.toString() === "Visual Vocal"|| this.props?.song?.abbreviation?.toString() ===  "Vis. Voc. / End Title"||  this.props?.song?.abbreviation?.toString() === "Vis. Voc. / End Title"||  this.props?.song?.abbreviation?.toString() === "BKG. VOC. / END TITLE"||  this.props?.song?.abbreviation?.toString() === "Bkg. Vocal / Opening Theme"||  this.props?.song?.abbreviation?.toString() === "Custom Use") {
                    if(e.target.checked) {
                      this.handleChange("userDefinedLanguage",1);
                      this.onChangeCallBack("quoteRequest_list", [this.state.quoteRequestOptionList?.find(item => item?.text?.toLowerCase() === 'subtitling permission')],null,null);
                    } else {
                      this.handleChange("userDefinedLanguage",0);
                      this.onChangeCallBack("quoteRequest_list", [],null,null);
                    }
                  }
                  this.handleCheckboxChange(
                    "quoteRequest",
                    e.target.checked ? 1 : 0
                  );
                  this.handleChange("email_letters",  1);
                  if(this.props?.licensorListData?.find(item => item.clearance_licensor_id === this.props?.lic.clearance_licensor_id).rights.find(item => item.clearance_licensor_rights_id)?.fee > 0 || this.props?.licensorListData?.find(item => item.clearance_licensor_id === this.props?.lic.clearance_licensor_id).rights.find(item => item.clearance_licensor_rights_id)?.fee === 0 )
                    {
                  this.handleChange("includeProposedFees",  1);
                  } 
                }}
                label={"Quote Request"}
                disabled={this.state.writeLQCPermissions == 1 ? false : true}
              />}

              {this.props?.lQCPermissions?.view  == 1 && !this.state.clearanceCOAOption && <BasicCheckbox
                id={"confirmation"}
                checked={this.state.licensorLetterData?.confirmation}
                onChange={(e) => {
                  this.handleCheckboxChange(
                    "confirmation",
                    e.target.checked ? 1 : 0
                  );
                  this.handleChange("email_letters",  1);
                }}
                label={"Confirmation"}
                disabled={this.state.writeLQCPermissions == 1 ? false : true}
              />}

              {this.props?.lQCPermissions?.view  == 1 && !this.state.clearanceCOAOption && this.props.lic?.is_useConfirmation === 1 && !isMusicSupervisor &&
              <BasicCheckbox
                id={"useConfirmation"}
                checked={this.state.licensorLetterData?.useConfirmation}
                onChange={(e) => {
                  this.handleCheckboxChange(
                    "useConfirmation",
                    e.target.checked ? 1 : 0
                  );
                  this.handleChange("email_letters",  1);
                }}
                label={"Use Confirmation"}
                disabled={this.state.writeLQCPermissions == 1 ? false : true}
              />}

              {this.props?.lettersPermission?.view === 1 && !this.state.clearanceCOAOption && <BasicCheckbox
                id={"licenseRequest"}
                checked={this.state.licensorLetterData?.licenseRequest}
                onChange={(e) => {
                  this.handleCheckboxChange(
                    "licenseRequest",
                    e.target.checked ? 1 : 0
                  );
                  this.handleChange("email_letters",  1);
                }}
                label={"License Request"}
                disabled={this.state.writeLettersPermission == 1 ? false : true}
              />}
              {//this.state.isMainTitle !== "mainTitle" ?
                 this.props?.lettersPermission?.view === 1 && <BasicCheckbox
                  id={"license"}
                  checked={this.state.licensorLetterData?.license}
                  onChange={(e) => {
                    this.handleCheckboxChange(
                      "license",
                      e.target.checked ? 1 : 0
                    );
                    this.handleChange("email_letters", 1);
                  }}
                  label={"License"}
                  disabled={this.state.writeLettersPermission == 1 ? false : true}
                /> //: null
              }
            </MDBCol>
            <MDBCol>
              { //this.state.isMainTitle !== "mainTitle" ?
                 this.props?.lettersPermission?.view === 1&&<BasicCheckbox
                  id={"licenseCoverLetter"}
                  checked={this.state.licensorLetterData?.licenseCoverLetter}
                  onChange={(e) => {
                    this.handleCheckboxChange(
                      "licenseCoverLetter",
                      e.target.checked ? 1 : 0
                    );
                    this.handleChange("email_letters", 1);
                  }}
                  label={"License Cover Letter"}
                  disabled={this.state.writeLettersPermission == 1 ? false : true}
                /> //: null
              }
              {/* {this.state.status === "Using" ? */}
              {this.props?.lettersPermission?.view === 1 && <BasicCheckbox
                id={"checkRequest"}
                checked={this.state.licensorLetterData?.checkRequest === 1}
                onChange={(e) => {
                  this.handleCheckboxChange(
                    "checkRequest",
                    e.target.checked ? 1 : 0
                  );
                }}
                label={"Check Request(Initial Fee)"}
                disabled={this.state.writeLettersPermission == 1 ? false : true}
              />}
              {/* : null} */}
              {/* {this.state.status === "Using" ? */}
              {this.props?.lettersPermission?.view === 1  && <BasicCheckbox
                id={"checkRequestAP"}
                checked={this.state.licensorLetterData?.checkRequestAP === 1}
                onChange={(e) => {
                  this.handleCheckboxChange(
                    "checkRequestAP",
                    e.target.checked ? 1 : 0
                  );
                }}
                label={"Check Request AP(Initial Fee)"}
                disabled={this.state.writeLettersPermission == 1 ? false : true}
              />}
              {/* : null} */}
              {/* {this.state.status === "Using" ? */}
              {this.props?.lettersPermission?.view === 1  && <BasicCheckbox
                id={"checkTransmittal"}
                checked={this.state.licensorLetterData?.checkTransmittal === 1}
                onChange={(e) => {
                  this.handleCheckboxChange(
                    "checkTransmittal",
                    e.target.checked ? 1 : 0
                  );
                }}
                label={"Check Transmittal(Initial Fee)"}
              />}
              {/* : null} */}
            </MDBCol>
            <MDBCol>
              {/* {this.state.status === "Using" ? */}
              {this.props?.lettersPermission?.view === 1  && <BasicCheckbox
                id={"checkTransmittalAP"}
                checked={this.state.licensorLetterData?.checkTransmittalAP === 1}
                onChange={(e) => {
                  this.handleCheckboxChange(
                    "checkTransmittalAP",
                    e.target.checked ? 1 : 0
                  );
                }}
                label={"Check Transmittal AP(Initial Fee)"}
              />}
              {/* : null} */}
              {this.state.isMainTitle !== "mainTitle" ?
                 this.props?.lettersPermission?.view === 1 && !this.state.clearanceCOAOption && <BasicCheckbox
                  id={"producerMemo"}
                  checked={this.state.licensorLetterData?.producerMemo}
                  onChange={(e) => {
                    this.handleCheckboxChange(
                      "producerMemo",
                      e.target.checked ? 1 : 0
                    );
                  this.handleChange("email_letters",  1);
                  }}
                  label={"Producer Memo"}
                  disabled={this.state.writeLettersPermission == 1 ? false : true}
                /> : null}
              {this.state.isMainTitle === "mainTitle" ?
                 this.props?.lettersPermission?.view === 1 && !this.state.clearanceCOAOption && <BasicCheckbox
                  id={"noticeOfUse"}
                  checked={this.state.licensorLetterData?.noticeOfUse}
                  onChange={(e) => {
                    this.handleCheckboxChange(
                      "noticeOfUse",
                      e.target.checked ? 1 : 0
                    );
                  }}
                  label={"Notice Of Use"}
                />
                : null}
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol>
              <div className="letterTab">
                <div className="tabContainer">
                  <Tabs
                    tabList={this.state.lettersTabList}
                    value={this.state.tabValue}
                    onChange={(e, v) => this.handleTabChange(v)}
                  />
                </div>
              </div>
            </MDBCol>
            {this.selectedTabComponent()}
          </MDBRow>
          <MDBRow>
            <MDBRow className="buttons">
              <MDBCol md={9}></MDBCol>
              <MDBCol md={2} id="addButton">
                <BasicButton
                  className="btnwidth"
                  variant="contained"
                  type="inline"
                  color="primary"
                  text={this.state.isPosting ? <CircularProgress color="inherit" size={18} /> : "Create"}
                  onClick={() => this.handleLetterCreation(this.state.licensorLetterData?.licensor_list, this.state.lettersTabList, false, true)}
                // onClick={this.openHandlePopup}
                />
              </MDBCol>
              <MDBCol md={1}>
                <BasicButton
                  type="inline"
                  variant="outlined"
                  text={"Cancel"}
                  onClick={this.props?.handleClose}
                />
              </MDBCol>
            </MDBRow>
          </MDBRow>
          {
            this.state.showExcelAndDocModal &&
            <>
              <MainViewModalComponent
                open={this.state.showExcelAndDocModal}
                handleClose={this.handleHideExcelAndDocModal}
                headerText={"Letter Generation"}
                //New Version - Version 1(Show name - TV Budget Deatils)
                mode={"Both"}
                modalName={"Letters Modal"}
                getDataToOpenExisting={this.getDataToOpenExisting}
                // excelRecord={this.state.excelData}
                // userContext={this.props?.userContext}
                fileName={"Letters"}
                lettersTabList={this.state.lettersTabList}
                response={this.state.response}
                getReportName={this.getReportName}
                isMainTitle={this.state.isMainTitle}
              // division_id={this.props.division_id}
              />
            </>
          }
        </MDBContainer>
      );
    }
  }
);